import { RequiredProgressStep, OptionalProgressStep } from "@/utils/path";

function ProgressBar(props: { progress: number }) {
  let progress = /iPhone|Android/i.test(window.navigator.userAgent)
    ? Math.round(
        (348 / RequiredProgressStep) * (props.progress > RequiredProgressStep ? RequiredProgressStep : props.progress),
      )
    : Math.round(
        (400 / RequiredProgressStep) * (props.progress > RequiredProgressStep ? RequiredProgressStep : props.progress),
      );
  const progressDetail = Math.round((107 / OptionalProgressStep) * (props.progress - RequiredProgressStep));
  props.progress === 0 && (progress = 100);

  return (
    <div className='absolute font-sans sm:w-[360px] md:left-[205px] md:w-[500px]'>
      <p className='absolute top-5 h-2 rounded-r-lg bg-gray-200 sm:left-2 sm:w-[340px] md:left-[-50px] md:w-[380px]'></p>
      <p
        className={`absolute top-5 z-50 h-2 rounded-l-lg rounded-r-lg bg-blue-800 ${
          props.progress === 0 ? "sm:left-0" : "sm:left-2"
        } md:left-[-75px]`}
        style={{ width: String(progress) + (props.progress === 0 ? "%" : "px") }}
      ></p>
      {props.progress !== 0 && (
        <p className='absolute h-2 rounded-r-lg bg-gray-100 sm:left-2 sm:top-12 sm:w-[100px] md:left-[322px] md:top-5 md:w-[105px]'></p>
      )}
      {props.progress === 0 ? (
        <p className='absolute left-[150px] top-0 h-2 text-[10px] font-bold text-blue-800'>ご確認ください</p>
      ) : props.progress > RequiredProgressStep ? (
        <p className='absolute top-0 h-2 text-[10px] font-bold text-blue-800 sm:left-[150px] md:left-[100px]'>
          基本項目完了！
        </p>
      ) : (
        <p className='absolute top-0 h-2 text-[10px] font-bold text-blue-800 sm:left-[150px] md:left-[100px]'>
          {props.progress}/{RequiredProgressStep}項目
        </p>
      )}
      {props.progress === 0 ? (
        ""
      ) : props.progress > RequiredProgressStep ? (
        <>
          <p className='absolute h-2 text-[10px] font-bold text-gray-300 sm:left-[35px] sm:top-8 md:left-[350px] md:top-0'>
            {props.progress - RequiredProgressStep}/{OptionalProgressStep}項目
          </p>
          <p
            className={`absolute top-5 h-2 rounded-l-lg rounded-r-lg bg-green-600 sm:left-2 md:left-[${String(
              progress,
            )}px] z-50 sm:top-12 sm:w-[102px] md:left-[320px] md:top-5`}
            style={{ width: progressDetail + "px" }}
          ></p>
        </>
      ) : (
        <p className='absolute h-2 text-[10px] font-bold text-gray-300 sm:left-[35px] sm:top-8 md:left-[350px] md:top-0'>
          詳細項目
        </p>
      )}
    </div>
  );
}

export default ProgressBar;
