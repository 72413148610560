import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { apiPost } from "@/utils/api";

interface Service {
  [key: string]: string;
}

function ServicePage() {
  const isMd = useMediaQuery();
  const { error, success } = useToast();
  const schema = yup.object({
    name: yup.string().required("お名前は必須入力項目です"),
    current_company: yup.string().required("現職企業名は必須入力項目です"),
    email: yup
      .string()
      .required("メールアドレスは必須入力項目です")
      .email("有効なメールアドレスを入力してください")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "有効なメールアドレスを入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; current_company: string; name: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Service> = async (params) => {
    const res = await apiPost("/api/side-job/notify", params);
    if (res.code === 200) {
      success("登録が完了しました");
      setTimeout(() => {
        location.reload();
      }, 3000);
    } else {
      error("登録に失敗しました");
    }
  };

  return (
    <div className='overflow-x-hidden overflow-y-hidden bg-blue-50 sm:mt-4 md:mt-0'>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <p className='flex w-full justify-center font-bold sm:mb-8 sm:mt-0 sm:text-[20px] md:mb-16 md:mt-8 md:text-[28px]'>
          {" "}
          カソークの「副業転職」紹介スタート
        </p>
        <p className='sm:mb-8 md:mb-0'>
          平素より、カソークサービスをご利用いただき、誠にありがとうございます。
          <br />
          カソークはこれまで、ミッション、ビジョンに基づいて、皆様に成長企業に特化した、副業、転職のサービスをご提供してきました。
        </p>
        <div className='w-full justify-center sm:my-4 md:my-0 md:flex md:p-8'>
          <div className='flex w-full justify-center'>
            <img src='/images/service_mission.png' className='sm:mb-4 sm:w-4/5 md:mb-0 md:mr-4 md:w-full' />
          </div>
          <img src='/images/service_vision.png' className='sm:w-full md:w-3/5' />
        </div>
        <p className='mb-8 sm:mt-8 md:mt-0'>
          その中で、
          <br />
          副業をご経験された方からは「<span className='font-bold text-blue-600'>そのまま入社してもよかった</span> 」「
          <span className='font-bold text-blue-600'>正社員としてもっとバリューを発揮したい</span>」というお声を、
          <br />
          転職の機会を探されている方からは「
          <span className='font-bold text-blue-600'>初めてのスタートアップ転職は情報が少なくて不安</span>」「
          <span className='font-bold text-blue-600'>もっと企業のことを知ってから入社を決めたい</span>」というお声を、
          <br />
          いただいてまいりました。 <br />
          そこで、カソークでは
          <span className='border-b border-gray-900 font-bold'>副業をしてお互いを理解してから入社する「副業転職」</span>
          のご紹介を開始いたします。
        </p>
        {!isMd ? (
          <img src='/images/service_feature.png' className='w-full p-8' />
        ) : (
          <img src='/images/service_feature_sp.png' className='w-full py-8' />
        )}
        {!isMd ? (
          <img src='/images/service_merit.png' className='w-full p-8' />
        ) : (
          <img src='/images/service_merit_sp.png' className='w-full py-8' />
        )}
        <p className=''>
          選りすぐりの「副業転職」の案件は、皆様宛のメールでお届けいたします。
          <br />
          担当からのご連絡をぜひお待ちいただけますと幸いです。
          <br />
          <br />
          下記に、ご登録いただけますと、今すぐ担当よりご連絡差し上げます。
          <br />
          最新の「副業転職」案件をいち早く確認したい場合は、お名前とメールアドレスをご登録ください。
        </p>
        <div className='mb-8 mt-16 sm:text-[12px] md:text-[16px]'>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>お名前</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("name")}
              />
            </div>
            <span className='absolute mt-1 text-xs text-red-500 sm:ml-[-40px] md:ml-[-32px]'>
              {errors.name?.message}
            </span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>現職企業名</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("current_company")}
              />
            </div>
            <span className='absolute mt-1 text-xs text-red-500 sm:ml-[-40px] md:ml-[-32px]'>
              {errors.current_company?.message}
            </span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>メールアドレス</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("email")}
              />
            </div>
            <span className='absolute mt-1 text-xs text-red-500 sm:ml-[-40px] md:ml-[-32px]'>
              {errors.email?.message}
            </span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <button
              type='button'
              className={
                "mt-4  h-[40px] w-[144px] cursor-pointer rounded-[4px] bg-blue-700 py-2 text-center font-bold leading-5 text-white hover:bg-blue-500"
              }
              onClick={handleSubmit(onSubmit)}
            >
              登録する
            </button>
          </div>

          <div className='mb-16 mt-24 flex w-full justify-center'>
            <p className=''>
              <p className='mb-4  flex w-full justify-center '>
                カソークの<span className='font-bold text-blue-600'>副業転職で、</span>
                <br />
              </p>
              <p className='mb-5  flex w-full justify-center '>
                「こんなはずじゃなかった<span className='text-xs md:mt-1'>・・・</span>」をなくし、
                <br />
              </p>
              <p className='flex w-full  justify-center border-2 border-blue-600 py-1'>
                「<span className='font-bold text-blue-600'>入社してよかった！</span>」と思える転職を
              </p>
            </p>
          </div>

          <p className='my-4'>弊社のリニューアルされたHPもぜひご覧ください。</p>
          <img src='/images/logo.svg'></img>
          <p className='my-4'>
            <a
              className='border-b border-blue-500 text-blue-600 hover:border-blue-300 hover:text-blue-300'
              href='https://corp.kasooku.jp/'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://corp.kasooku.jp/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
