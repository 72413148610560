import { useEffect, useState } from "react";

import QuestionnaireModal from "@/components/layouts/settings/QuestionnaireModal";
import WithdrawModal from "@/components/layouts/settings/WithdrawModal";
import useMediaQuery from "@/hooks/useMediaQuery";

function Withdraw() {
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [isOpenQuestionnaireModal, setIsOpenQuestionnaireModal] = useState(false);
  const isMobile = useMediaQuery();
  return (
    <div className='w-full pt-2'>
      {isOpenWithdrawModal && (
        <WithdrawModal
          setIsOpenWithdrawModal={setIsOpenWithdrawModal}
          setIsOpenQuestionnaireModal={setIsOpenQuestionnaireModal}
        />
      )}
      {isOpenQuestionnaireModal && <QuestionnaireModal setIsOpenQuestionnaireModal={setIsOpenQuestionnaireModal} />}

      {!isMobile && (
        <p className='mb-6 text-[12px] text-red-400'>
          退会すると、今までのデータは全て削除され、復元ができません。
          <br /> kasookuサービスの利用ができなくなり、ご利用には再度登録が必要となります。
          <br />
          退会後はお問合せが出来なくなりますので、ご注意ください。
          <br />
        </p>
      )}
      <div className={`w-full ${isMobile && "text-right"}`}>
        <button
          onClick={() => setIsOpenWithdrawModal(true)}
          className='h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
        >
          退会する
        </button>
      </div>
    </div>
  );
}

export default Withdraw;
