import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm, SubmitHandler, Resolver } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { CareerSelector } from "@/stores/selectors/CareerSelector";
import { userSelector } from "@/stores/selectors/userSelector";
import { Career } from "@/types/user";
import { apiPost } from "@/utils/api";

interface Props {
  cancel?: () => void;
  setIsAddCareer: (arg0: boolean) => void;
  profileSubmit?: () => void;
  bgColor: string;
  isProfile: boolean;
  ind: number;
  setIsDelete: (arg0: boolean) => void;
  setDeleteIndex: (arg0: number) => void;
  isSubmit: boolean;
  setIsSubmit: (arg0: boolean) => void;
  handleFormValidation: (arg0: number) => void;
}

export const CareerForm = (props: Props) => {
  useControlBlowserBack();
  const navigate = useNavigate();
  const [, setKey] = useRecoilState(locationState);
  const [Career] = useRecoilState(CareerSelector);
  const [user] = useRecoilState(userSelector);
  const { error } = useToast();

  const startDate = new Date(user.user_careers[props.ind]?.start_at);
  const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, "0")}`;

  let formattedEndDate = dayjs().format("YYYY-MM");
  
  if (!user.user_careers[props.ind]?.is_employed) {
    const endDate = new Date(user.user_careers[props.ind]?.end_at);
    formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, "0")}`;
  }
  
  const isValidYearMonthFormat = (value: string) => {
    return /^\d{4}-\d{2}$/.test(value);
  };

  const isValidDateRange = (startDate: string, endDate: string) => {
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    return (
      parsedStartDate >= new Date("1900-01") &&
      parsedEndDate >= new Date("1900-01") &&
      parsedEndDate <= new Date() &&
      parsedEndDate >= parsedStartDate
    );
  };

  const isValidValue = (value: string, context: yup.TestContext) => {
    return !value || (isValidYearMonthFormat(value) && isValidDateRange(value, context.parent.end_at));
  };

  const createYearMonthTest = (fieldName: string) => {
    return yup
      .string()
      .required(`${fieldName}は必須項目です`)
      .test(`valid-${fieldName}-date`, `正しい形式で入力してください(例: 2020-04 ~ 2022-03)`, function (value) {
        return isValidValue(value, this);
      });
  };

  const schema = yup.object({
    id: yup.number().nullable(),
    company_name: yup.string().required("企業名は必須項目です"),
    start_at: createYearMonthTest("start"),
    end_at: createYearMonthTest("end"),
    position: yup.string().required("役職は必須項目です"),
    department: yup.string().required("部署は必須項目です"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Career>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema) as unknown as Resolver<Career>,
    defaultValues: {
      id: user.user_careers[props.ind]?.id,
      company_name: user.user_careers[props.ind]?.company_name,
      start_at: formattedStartDate,
      end_at: formattedEndDate,
      position: user.user_careers[props.ind]?.position,
      department: user.user_careers[props.ind]?.department,
      content: user.user_careers[props.ind]?.content,
      is_employed: user.user_careers[props.ind]?.is_employed,
    },
  });

  const onSubmit: SubmitHandler<Career> = async (params) => {
    const startDate = new Date(params.start_at);
    const endDate = new Date(params.end_at);
    params.start_at = `${startDate.getFullYear()}/${(startDate.getMonth() + 1).toString().padStart(2, "0")}`;
    params.end_at = `${endDate.getFullYear()}/${(endDate.getMonth() + 1).toString().padStart(2, "0")}`;
    if (watch("is_employed")) {
      params.end_at = "";
    }

    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiPost("/api/user/career", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && !props.isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/desired-condition" }));
      navigate("/register/desired-condition");
    }
    props.handleFormValidation(props.ind);

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  const handleChangeIsEmployed = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setValue("is_employed", isChecked);
    isChecked && setValue("end_at", dayjs().format("YYYY-MM"));
    !isChecked && setValue("end_at", "");
  };

  useEffect(() => {
    if (props.isSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [props.isSubmit]);

  return (
    <div className={`form-group sm:p-2 md:p-6 ${props.bgColor}`}>
      <Loading />
      <div>
        <div className='mb-8 md:w-1/3'>
          <label htmlFor='company_name' className='text-[14px] font-bold text-gray-500'>
            企業名{" "}
            <span className='mb-2 ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </label>
          <input
            type='text'
            className='border-grey-light block w-full rounded-md border p-3'
            placeholder='企業名'
            {...register("company_name")}
          />
          <span className='absolute p-1 text-xs text-red-500'>{errors.company_name?.message}</span>
        </div>{" "}
        <div className='sm:full mb-8 md:w-3/5'>
          <label htmlFor='period' className='text-[14px] font-bold text-gray-500'>
            期間{" "}
            <span className='mb-2 ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </label>
          <div className='md:flex'>
            <div className='sm:flex'>
              <input
                type='month'
                min='1900-01'
                max='2100-12'
                className='h-10 w-32 rounded-md border border-gray-200 p-2 text-sm sm:mr-0 md:mr-2'
                placeholder='2020/01'
                {...register("start_at")}
              />
              <p className='pt-2'>～</p>
              <input
                disabled={watch("is_employed") as boolean}
                type='month'
                min='1900-01'
                max='2100-12'
                className={`${
                  (watch("is_employed") as boolean) && "bg-white text-white"
                } h-10 w-32 rounded-md border border-gray-200 p-2 text-sm sm:ml-0 md:ml-2`}
                {...register("end_at")}
              />
            </div>
            <div className='sm:mt-2 sm:flex md:mt-1'>
              <input
                type='checkbox'
                className='rounded-md border border-gray-200 sm:ml-0 sm:mr-4 md:mx-4 md:p-3'
                onChange={handleChangeIsEmployed}
                defaultChecked={user.user_careers[props.ind]?.is_employed as boolean}
              />
              <p className='text-[14px] md:pt-2'>在籍中</p>
            </div>
          </div>
          <span className='absolute p-1 text-xs text-red-500'>
            {errors.start_at ? errors.start_at.message : errors.end_at?.message}
          </span>
        </div>
        <div className='mb-8 sm:w-full md:w-1/3'>
          <label htmlFor='position' className='text-[14px] font-bold text-gray-500'>
            役職{" "}
            <span className='mb-2 ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </label>
          <input
            type='text'
            className='border-grey-light block w-full rounded-md border p-3'
            {...register("position")}
          />
          <span className='absolute p-1 text-xs text-red-500'>{errors.position?.message}</span>
        </div>{" "}
        <div className='mb-8 sm:w-full md:w-1/3'>
          <label htmlFor='department' className='text-[14px] font-bold text-gray-500'>
            部署{" "}
            <span className='mb-2 ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </label>
          <input
            type='text'
            className='border-grey-light block w-full rounded-md border p-3'
            placeholder='〇〇部'
            {...register("department")}
          />
          <span className='absolute p-1 text-xs text-red-500'>{errors.department?.message}</span>
        </div>
        <div className='mb-8'>
          <label htmlFor='content' className='text-[14px] font-bold text-gray-500'>
            業務内容
          </label>
          <textarea
            className='border-grey-light block h-[100px] w-full resize-none rounded-md border p-3 '
            placeholder='あなたが主に従事したことや、部署や所属部署の役割、担当業務など'
            {...register("content")}
          />
          <span className='absolute p-1 text-xs text-red-500'>{errors.content?.message}</span>
        </div>
        {Career.formInd === 1 ? (
          <div className='w-1/2 pr-2'>
            <button
              disabled
              type='submit'
              className='border-spacing-1 rounded-md border py-3 text-center text-[13px] font-bold text-gray-400 sm:my-2 sm:w-full md:w-1/2'
            >
              この項目を削除
            </button>
          </div>
        ) : (
          <div className='w-1/2 pr-2'>
            <button
              type='submit'
              className='border-spacing-1 rounded-md border py-3 text-center text-[13px] font-bold text-blue-700 hover:bg-blue-100 focus:outline-none sm:my-2 sm:w-full md:w-1/2'
              onClick={() => {
                props.setDeleteIndex(props.ind);
                props.setIsDelete(true);
              }}
            >
              この項目を削除
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CareerForm;
