import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useRecoilState } from "recoil";

import Badge from "@/components/layouts/parts/Badge";
import AnnualIncome from "@/components/layouts/profile/AnnualIncome";
import Career from "@/components/layouts/profile/Career";
import ConfirmConditionModal from "@/components/layouts/profile/ConfirmConditionModal";
import CurrentCompany from "@/components/layouts/profile/CurrentCompany";
import CurrentIndustry from "@/components/layouts/profile/CurrentIndustry";
import CurrentJob from "@/components/layouts/profile/CurrentJob";
import DesireIndustry from "@/components/layouts/profile/DesireIndustry";
import DesireJob from "@/components/layouts/profile/DesireJob";
import EditAnnualIncome from "@/components/layouts/profile/EditAnnualIncome";
import EditCareer from "@/components/layouts/profile/EditCareer";
import EditCurrentCompany from "@/components/layouts/profile/EditCurrentCompany";
import EditCurrentIndustry from "@/components/layouts/profile/EditCurrentIndustry";
import EditCurrentJob from "@/components/layouts/profile/EditCurrentJob";
import EditDesireJob from "@/components/layouts/profile/EditDesireJob";
import EditLastEducation from "@/components/layouts/profile/EditLastEducation";
import EditModal from "@/components/layouts/profile/EditModal";
import EditResume from "@/components/layouts/profile/EditResume";
import FinalEducation from "@/components/layouts/profile/FinalEducation";
import JobList from "@/components/layouts/profile/JobList";
import MyProfile from "@/components/layouts/profile/MyProfile";
import RegistrationCompleteModal from "@/components/layouts/profile/RegistrationCompleteModal";
import Resume from "@/components/layouts/profile/Resume";
import SideMenue from "@/components/layouts/profile/SideMenue";
import TabLayout from "@/components/layouts/profile/TabLayout";
import useMediaQuery from "@/hooks/useMediaQuery";
import useScroll from "@/hooks/useScroll";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { ApiResponse, apiGet } from "@/utils/api";

function ProfilePage() {
  const [searchParams] = useSearchParams();

  const [user, setUser] = useRecoilState(userSelector);
  const [key] = useRecoilState(locationState);
  const scroll = useScroll();
  const isSm = useMediaQuery();
  const [isFixed, setIsFixed] = useState(false);
  const [isAddAnnualIncome, setIsAddAnnualIncome] = useState(false);
  const [isAddLastEducation, setIsAddLastEducation] = useState(false);
  const [isAddCurrentIndustry, setIsAddAddCurrentIndustry] = useState(false);
  const [isAddCurrentJob, setIsAddAddCurrentJob] = useState(false);
  const [isAddCurrentCompany, setIsAddCurrentCompany] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isAddResume, setIsAddResume] = useState(false);
  const [isAddCareer, setIsAddCareer] = useState(false);
  const [isAddDesireIndustry, setIsAddDesireIndustry] = useState(false);
  const [isAddDesireJob, setIsAddDesireJob] = useState(false);
  const [activeTab, setActiveTab] = useState(key.component);
  const [isConfirmConditionModalOpen, setIsConfirmConditionModalOpen] = useState(false);

  useEffect(() => {
    setActiveTab(key.component);
    const modalQuery = searchParams.get("modal");
    if (modalQuery) {
      setIsCompleteModalOpen(true);
    }
  }, [key.component]);

  useEffect(() => {
    if (!isSm && scroll > 638) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, [scroll]);

  const checkUser = async () => {
    const res: ApiResponse<User> = await apiGet<User>("/api/user/me");
    if ("data" in res && res.success) {
      setUser(res.data);
      return;
    }
  };

  // ポップアップ表示処理
  useEffect(() => {
    if (!user.last_displayed_at) {
      setIsConfirmConditionModalOpen(true);
      return;
    }
    const userDate = new Date(user.last_displayed_at);
    const currentDate = new Date();
    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    userDate < oneMonthAgo ? setIsConfirmConditionModalOpen(true) : setIsConfirmConditionModalOpen(false);
  }, [user]);

  useEffect(() => {
    if (!isSm && window.scrollY >= 1300) setIsFixed(false);
  }, [window.scrollY]);

  return (
    <div className='overflow-x-hidden font-sans'>
      {!isCompleteModalOpen && isConfirmConditionModalOpen && (
        <ConfirmConditionModal setIsConfirmConditionModalOpen={setIsConfirmConditionModalOpen} />
      )}
      {isEditModalOpen && <div className='bg-black fixed left-0 top-0 h-screen w-screen bg-opacity-10' />}
      {isEditModalOpen && (
        <EditModal setIsEditModalOpen={setIsEditModalOpen} setIsCompleteModalOpen={setIsCompleteModalOpen} />
      )}
      {isCompleteModalOpen && <RegistrationCompleteModal setIsCompleteModalOpen={setIsCompleteModalOpen} />}
      <div className='flex justify-center bg-blue-100'>
        <div className='pt-[40px] sm:w-screen md:w-[1000px]'>
          <MyProfile setIsEditModalOpen={setIsEditModalOpen} />
          <TabLayout setActiveTab={setActiveTab} />
        </div>
      </div>
      {activeTab === "profile" ? (
        <div className='flex justify-center bg-white'>
          <div className='mt-8 flex md:w-[1000px]'>
            <div className={`${isFixed ? "fixed top-5 " : "relative"}`}>
              <SideMenue />
            </div>
            <div className='overflow-x-hidden pl-8 sm:pr-8 md:pr-0' style={{ marginLeft: isFixed ? "175px" : "0" }}>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='resume'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='md:flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>履歴書・職務経歴書</h2>
                    <Badge isDisplay={user.resumes?.file.length === 0 && user.resumes?.url.length === 0} />
                  </div>
                  {!isAddResume && (
                    <button
                      onClick={() => setIsAddResume(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddResume ? (
                  <EditResume setIsAddResume={setIsAddResume} bgColor={"bg-blue-50"} isProfile={true} />
                ) : (
                  <Resume />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='anual-income'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>現在の年収</h2>
                    <Badge isDisplay={!user.annual_income} />
                  </div>
                  {!isAddAnnualIncome && (
                    <button
                      onClick={() => setIsAddAnnualIncome(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddAnnualIncome ? (
                  <EditAnnualIncome
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAnnualIncome(false);
                    }}
                    cancel={() => {
                      setIsAddAnnualIncome(false);
                    }}
                    isProfile={true}
                  />
                ) : (
                  <AnnualIncome />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='last-education'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>最終学歴</h2>
                    <Badge isDisplay={!user.user_schools.id} />
                  </div>
                  {!isAddLastEducation && (
                    <button
                      onClick={() => setIsAddLastEducation(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddLastEducation ? (
                  <EditLastEducation
                    profileSubmit={() => {
                      checkUser();
                      setIsAddLastEducation(false);
                    }}
                    isProfile={true}
                    cancel={() => {
                      setIsAddLastEducation(false);
                    }}
                  />
                ) : (
                  <FinalEducation />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='current-industry'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>現在の業種</h2>
                    <Badge isDisplay={!user.current_industry} />
                  </div>
                  {!isAddCurrentIndustry && (
                    <button
                      onClick={() => setIsAddAddCurrentIndustry(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentIndustry ? (
                  <EditCurrentIndustry
                    isProfile={true}
                    user={user}
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAddCurrentIndustry(false);
                    }}
                    cancel={() => setIsAddAddCurrentIndustry(false)}
                  />
                ) : (
                  <CurrentIndustry />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='current-job'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>現在の職種</h2>
                    <Badge isDisplay={!user.current_job} />
                  </div>
                  {!isAddCurrentJob && (
                    <button
                      onClick={() => setIsAddAddCurrentJob(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentJob ? (
                  <EditCurrentJob
                    isProfile={true}
                    user={user}
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAddCurrentJob(false);
                    }}
                    cancel={() => setIsAddAddCurrentJob(false)}
                  />
                ) : (
                  <CurrentJob />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='current-company'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>現職企業名</h2>
                    <Badge isDisplay={!user.current_company} />
                  </div>
                  {!isAddCurrentCompany && (
                    <button
                      onClick={() => setIsAddCurrentCompany(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentCompany ? (
                  <EditCurrentCompany
                    setIsAddCurrentCompany={setIsAddCurrentCompany}
                    bgColor={"bg-blue-50"}
                    isProfile={true}
                  />
                ) : (
                  <CurrentCompany />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='career'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>職歴</h2>
                    <Badge isDisplay={user.user_careers.length === 0} />
                  </div>
                  {!isAddCareer && (
                    <button
                      onClick={() => setIsAddCareer(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCareer ? (
                  <EditCareer setIsAddCareer={setIsAddCareer} bgColor={"bg-blue-50"} isProfile={true} />
                ) : (
                  <Career />
                )}
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='desire-industry'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>希望業種</h2>
                    <Badge isDisplay={user.user_desired_industries.length === 0} />
                  </div>
                  {!isAddDesireIndustry && (
                    <button
                      onClick={() => setIsAddDesireIndustry(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                <DesireIndustry
                  setIsAddDesireIndustry={setIsAddDesireIndustry}
                  isAddDesireIndustry={isAddDesireIndustry}
                />
              </div>
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='desire-job'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex'>
                    <h2 className='mr-1 flex-grow-0 text-[20px]'>希望職種</h2>
                    <Badge isDisplay={user.user_desired_job_types.length === 0} />
                  </div>
                  {!isAddDesireJob && (
                    <button
                      onClick={() => setIsAddDesireJob(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddDesireJob ? (
                  <EditDesireJob setIsAddDesireJob={setIsAddDesireJob} isProfile={true} />
                ) : (
                  <DesireJob />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=''>
          <JobList />
        </div>
      )}
    </div>
  );
}

export default ProfilePage;
