import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import EditButtonGroup from "@/components/layouts/parts/EditButtonGroup";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiFile } from "@/utils/api";
import { getMe } from "@/utils/me";

interface Props {
  cancel?: () => void;
  setIsAddResume: (arg0: boolean) => void;
  profileSubmit?: () => void;
  bgColor: string;
  isProfile: boolean;
}

interface Params {
  resume_file?: FileList[] | null;
  resume_url?: string[] | null;
  deletes?: number[];
}

export const EditResume = (props: Props) => {
  useControlBlowserBack();
  const navigate = useNavigate();
  const isMobile = useMediaQuery();
  const [user, setUser] = useRecoilState(userSelector);
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [params, setParams] = useState<Params>({
    resume_file: null,
    resume_url: null,
    deletes: [],
  });
  const [fileErrors, setFileErrors] = useState({
    resumeFile: "",
    resumeUrl: "",
  });
  const allowedMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ];
  const [urlError, setUrlError] = useState("");
  const [urlInput, setUrlInput] = useState("");

  // 保存処理
  const handleSubmit = async () => {
    setFileErrors({
      resumeFile: "",
      resumeUrl: "",
    });
    // ファイルのチェック
    if (!params.resume_file && !params.resume_url && params.deletes?.length === 0) {
      setFileErrors((prevValue) => ({
        ...prevValue,
        resumeFile: "ファイルをアップロードするか、URLを入力してください",
      }));
      return;
    }
    let newParams: Params = {
      resume_file: params.resume_file?.flatMap((param) => param || []),
      resume_url: params.resume_url?.flatMap((param) => param || []),
    };
    if (params.deletes && params.deletes.length > 0) {
      newParams = params;
    }

    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiFile("/api/user/resume", newParams);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && !props.isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/career" }));
      navigate("/register/career");
      return;
    }
    if (res.code === 200 && props.isProfile) {
      getMe().then((user) => {
        user && setUser(user as User);
      });
      props.setIsAddResume(false);
    }
    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  // 後でアップロード
  const handleLaterUpload = () => {
    setKey((prevValue) => ({ ...prevValue, path: "/register/desired-condition" }));
    navigate("/register/career");
  };

  // 未作成の方
  const handleSkip = () => {
    setKey((prevValue) => ({ ...prevValue, path: "/register/career" }));
    navigate("/register/career");
  };

  // キャンセル処理
  const handleCancel = () => {
    props.setIsAddResume(false);
  };

  // URl処理
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrlError("");
    setUrlInput(e.target.value);
  };

  // URLのセット
  const handleUrlSubmit = () => {
    if (urlInput.trim().length === 0) {
      setUrlError("URLを入力してください");
      return;
    }
    if (urlInput.trim().length > 32768) {
      setUrlError("URLは、32768文字以下でなければいけません。");
      return;
    }
    const userUrlLen = user.resumes ? user.resumes.url.length : 0;
    const urlLen = params.resume_url ? params.resume_url.length : 0;
    if (userUrlLen + urlLen >= 5) {
      setUrlError("URLの登録は最大5件までです");
      return;
    }
    if (!yup.string().url().isValidSync(urlInput)) {
      setUrlError("有効なURLを入力してください");
      return;
    }

    setParams((prevParams) => ({
      ...prevParams,
      resume_url: [...(prevParams.resume_url || []), urlInput],
    }));
    setUrlInput("");
  };

  // ファイルのセット
  const handleFileUpload = (files: FileList | null) => {
    setFileErrors({
      resumeFile: "",
      resumeUrl: "",
    });

    if (!files || files[0].size > 10 * 1024 * 1024) {
      setFileErrors((prevValue) => ({ ...prevValue, resumeFile: "ファイルサイズは10MB以下にしてください" }));
      return;
    }
    if (!allowedMimeTypes.includes(files[0].type)) {
      setFileErrors((prevValue) => ({ ...prevValue, resumeFile: "ファイル形式が許可されていません" }));
      return;
    }
    const userFileLen = user.resumes ? user.resumes.file.length : 0;
    const fileLen = params.resume_file ? params.resume_file.length : 0;
    if (userFileLen + fileLen >= 3) {
      setFileErrors((prevValue) => ({ ...prevValue, resumeFile: "ファイルの登録は最大3件までです" }));
      return;
    }

    setParams((prevParams) => ({
      ...prevParams,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resume_file: [...(prevParams.resume_file || []), (files as FileList)[0] as any],
    }));
  };

  // ファイル削除処理
  const handleFileDelete = (index: number, id: number | null = null) => {
    setFileErrors({
      resumeFile: "",
      resumeUrl: "",
    });
    // 変数から削除
    const newResumeFile = params.resume_file && params.resume_file.filter((file) => file.length !== 0);
    newResumeFile && newResumeFile.splice(index, 1);
    setParams({
      ...params,
      resume_file: newResumeFile,
    });

    // DBから削除
    user.resumes?.file && user.resumes.file.length > 0 && setParams({ deletes: [id] as number[] });
  };

  // URL削除処理
  const handleUrlDelete = (index: number, id: number | null = null) => {
    setUrlError("");
    // 変数から削除
    const newResumeUrl = params.resume_url && params.resume_url.filter((url) => url !== "");
    newResumeUrl && newResumeUrl.splice(index, 1);
    setParams({
      ...params,
      resume_url: newResumeUrl,
    });

    // DB + から削除
    user.resumes?.url && user.resumes.url.length > 0 && setParams({ deletes: [id] as number[] });
  };

  useEffect(() => {
    if (params.deletes && params.deletes.length > 0) {
      handleSubmit();
    }
  }, [params]);
  return (
    <div className={`form-group mb-6 p-6 ${props.bgColor}`}>
      <Loading />
      <div>
        <div className='mb-6 mr-1'>
          {!props.isProfile && (
            <label htmlFor='resume' className='text-[14px] font-bold'>
              履歴書・職務経歴書アップロード
            </label>
          )}
          <p className='my-6 text-[14px]'>
            ご経歴について、下記の方法での登録が可能です
            <br />
            ・ファイルのアップロード
            <br />
            ・ファイルを格納したフォルダURLの登録
            <br />
            ・ポートフォリオやGithubアカウントURLの登録
          </p>
          <p className='my-2 text-[14px] font-bold text-gray-500'>ファイルアップロード</p>
          <label
            htmlFor='fileInput'
            className='border-grey-light block w-fit cursor-pointer rounded-md border bg-blue-700 px-4 py-3 text-[12px] font-bold text-white'
          >
            ファイルをアップロード
          </label>
          <input
            type='file'
            id='fileInput'
            className='hidden'
            onChange={(e) => handleFileUpload(e.target.files)} // ファイル選択時にバリデーションをトリガー
          />
          <p className='my-1 text-[12px] text-gray-500'>ファイルサイズは10MB以下にしてください。</p>
          <span className='absolute text-xs text-red-500'>{fileErrors.resumeFile}</span>

          <div className='mt-8'>
            {user.resumes?.file &&
              user.resumes.file.length > 0 &&
              user.resumes.file.map((item, index) => (
                <div key={index}>
                  <div className='mt-1 flex items-center'>
                    <p className='mr-2 text-[12px] font-bold'>{item.file_name}</p>
                    <span className='text-[12px] text-gray-500'>
                      {new Date(item.upload_at).toLocaleString([], {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                    <button onClick={() => handleFileDelete(index, item.id)}>
                      <img src='/images/trash.svg' className='ml-2 h-4 w-4' />
                    </button>
                  </div>
                  <div className='mb-2 mt-1 text-[12px] text-gray-500'>
                    {item.file_extension}ファイル&nbsp;
                    {item.file_size}
                  </div>
                </div>
              ))}
            <div>
              {params.resume_file &&
                params.resume_file.length > 0 &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                params.resume_file.map((item: any, index) => (
                  <div key={index}>
                    <div className='mt-1 flex items-center'>
                      <div className='flex'>
                        <p className='mr-2 text-[12px] font-bold'>{item.name}</p>
                        <span className='text-[12px] text-gray-500'>
                          {new Date().toLocaleString([], {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>
                      <button onClick={() => handleFileDelete(index)}>
                        <img src='/images/trash.svg' className='ml-2 h-4 w-4' />
                      </button>
                    </div>
                    <div className='mb-2 mt-1 text-[12px] text-gray-500'>
                      {item.type?.match(/\/([^/]+)$/)[1] && item.type.match(/\/([^/]+)$/)[1] + "ファイル"}&nbsp;
                      {Math.floor(item.size / 1000)}KB
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={`form-group mb-6 ${props.bgColor}`}>
          <div className='mb-6'>
            <label htmlFor='fileInput' className='text-[14px] font-bold text-gray-500'>
              URL登録
            </label>
            <input
              type='text'
              className='border-grey-light mt-2 block w-full rounded-md border p-3'
              placeholder='https://'
              name='url'
              value={urlInput} // ステートの値を入力値として設定
              onChange={handleUrlChange} // イベントハンドラを設定
            />
            {urlError !== "" && <span className='absolute pt-1 text-xs text-red-500'>{urlError}</span>}
          </div>
          <div className='mb-4'>
            <button
              type='button'
              className='border-grey-light block w-fit cursor-pointer rounded-md border bg-blue-700 px-4 py-3 text-[12px] font-bold text-white'
              onClick={handleUrlSubmit} // イベントハンドラを設定
            >
              登録する
            </button>
          </div>
        </div>
        {params.resume_url?.map((param, index) => (
          <div key={index} className='mb-2 flex items-center'>
            <p className='mr-2 text-[12px] font-bold'>{param}</p>
            <p className='text-[12px] text-gray-500'>
              {new Date().toLocaleString([], {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            {param && (
              <button onClick={() => handleUrlDelete(index)}>
                <img src='/images/trash.svg' className='ml-2 h-4 w-4' />
              </button>
            )}
          </div>
        ))}

        {user.resumes?.url?.map((item, index) => (
          <div key={index} className='mb-2 flex items-center'>
            <p className='mr-2 text-[12px] font-bold'>{item.url}</p>
            <p className='text-[12px] text-gray-500'>{item.upload_at}</p>
            {item && (
              <button onClick={() => handleUrlDelete(index, item.id)}>
                <img src='/images/trash.svg' className='ml-2 h-4 w-4' />
              </button>
            )}
          </div>
        ))}

        {props.isProfile ? (
          <div className='flex justify-end'>
            <EditButtonGroup submit={handleSubmit} cancel={handleCancel} isSubmit={true} />
          </div>
        ) : (
          <div className='text-center'>
            <div className='md:mx-4 md:flex'>
              <button
                type='submit'
                className='w-full rounded-full border border-blue-700 py-3 text-center text-[13px] font-bold text-blue-700 hover:bg-blue-100 focus:outline-none sm:my-2 md:m-2'
                onClick={handleLaterUpload}
              >
                後でアップロードする
              </button>
              {(params.resume_file && params.resume_file.length > 0) ||
              (params.resume_url && params.resume_url.length > 0) ? (
                <button
                  type='submit'
                  className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                  onClick={handleSubmit}
                >
                  次へ
                </button>
              ) : (
                <button
                  disabled
                  type='submit'
                  className='w-full rounded-full bg-gray-300 py-3 text-center text-[13px] font-bold text-white sm:my-2 md:m-2'
                  onClick={handleSubmit}
                >
                  次へ
                </button>
              )}
            </div>
            <button onClick={handleSkip} className='border-b text-[12px] text-gray-500 hover:text-gray-300'>
              未作成の方はこちら
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditResume;
