import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditLastEducation from "@/components/layouts/profile/EditLastEducation";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";

interface LastEducation {
  [key: string]: string;
}

function LastEducationPage() {
  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[525px] sm:w-[375px] md:top-[111px] md:h-[602px] md:w-[760px]'>
          <ProgressBar progress={7} />
          <EditLastEducation isProfile={false} />
        </div>
      </div>
    </div>
  );
}

export default LastEducationPage;
