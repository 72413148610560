import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import EditButtonGroup from "../parts/EditButtonGroup";
import MultiSelectButton from "../parts/MultiSelectButton";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { masterSelector } from "@/stores/selectors/masterSelector";
import { userSelector } from "@/stores/selectors/userSelector";
import { Master } from "@/types/master";
import { User } from "@/types/user";
import { ApiResponse, apiPost, apiGet } from "@/utils/api";
import { getMe } from "@/utils/me";

interface Props {
  isProfile: boolean;
  cancel?: () => void;
  profileSubmit?: () => void;
  setIsAddDesireJob?: (arg0: boolean) => void;
}

export const EditDesireJob = (props: Props) => {
  useControlBlowserBack();
  const navigate = useNavigate();
  const { error } = useToast();
  const [user, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  const [master, setMaster] = useRecoilState(masterSelector);
  const { isProfile } = props;
  const [selectedJobs, setSelectedJobs] = useState<number[]>(
    user.user_desired_job_types.map((item) => item.job_type_id) ?? [],
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const schema = yup.object({
    job_type_id: yup
      .array()
      .required("希望職種は必須入力項目です")
      .min(1, "希望職種は必須入力項目です")
      .max(3, "希望職種の項目数は3個以下にしてください"),
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<{ job_type_id: number[] }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      job_type_id: user.user_desired_job_types.map((item) => item.job_type_id) ?? [],
    },
  });

  const submit = async (params: { job_type_id: number[] }) => {
    !props.isProfile && setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiPost("/api/user/desired-job-types", params);

    if (res.code === 200 && !props.isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/profile", component: "profile", isLoading: false }));
      navigate({
        pathname: "/profile",
        search: "?modal=true",
      });
      return;
    }
    getMe().then((user) => {
      user && setUser(user as User);
    });
    props.setIsAddDesireJob && props.setIsAddDesireJob(false);

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  const handleCancel = () => {
    props.setIsAddDesireJob && props.setIsAddDesireJob(false);
  };

  useEffect(() => {
    if (master.jobs.length > 0) {
      return;
    }

    const fetchJobs = async () => {
      try {
        const res: ApiResponse<Master[]> = await apiGet<Master[]>("/api/job-types");
        !res.success && console.error(res.message);
        "data" in res && setMaster({ ...master, jobs: res.data });
      } catch (error) {
        console.error(error);
      }
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    if (selectedJobs.length >= 3) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [selectedJobs]);

  return (
    <div
      className={` ${
        isProfile
          ? "rounded-[6px] bg-blue-50 p-[24px]"
          : "mt-14 w-[400px] sm:top-[110px] sm:max-w-[320px] md:top-[90px] md:max-w-[400px]"
      }`}
    >
      <Loading />
      {!isProfile && (
        <label htmlFor='desireJob' className='text-[14px] font-bold'>
          希望職種
        </label>
      )}
      <div className='mt-5 flex flex-col gap-5'>
        <div className={`flex flex-wrap ${!isProfile && "justify-start"} sm:gap-2.5 md:gap-5`}>
          {master.jobs.map((v) => (
            <MultiSelectButton
              key={v.id}
              value={v.id}
              isSelected={selectedJobs.includes(v.id)}
              select={(values: number[]) => {
                setSelectedJobs((prevSelectedJobs) => {
                  const updatedSelectedJobs = prevSelectedJobs.includes(v.id)
                    ? prevSelectedJobs.filter((id) => id !== v.id)
                    : [...prevSelectedJobs, ...values];

                  if (isProfile) {
                    setValue("job_type_id", updatedSelectedJobs);
                  } else {
                    setValue("job_type_id", updatedSelectedJobs);
                  }

                  return updatedSelectedJobs;
                });
              }}
              isDisabled={isDisabled}
              values={[v.id]}
            >
              {v.name}
            </MultiSelectButton>
          ))}
        </div>
      </div>
      {errors && <span className='p-1 text-xs text-red-500'>{errors.job_type_id?.message}</span>}
      {isProfile ? (
        <div className='flex items-center justify-end'>
          <EditButtonGroup submit={handleSubmit(submit)} cancel={handleCancel} isSubmit={true} />
        </div>
      ) : (
        <div className='bg-white text-center '>
          <div className='w-full text-center sm:top-[350px] md:top-[300px]'>
            {selectedJobs.length > 0 ? (
              <button
                type='submit'
                className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                onClick={handleSubmit(submit)}
              >
                次へ
              </button>
            ) : (
              <button
                disabled
                type='submit'
                className='w-full rounded-full bg-gray-300 py-3 text-center text-[13px] font-bold text-white sm:my-2 md:m-2'
              >
                次へ
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDesireJob;
