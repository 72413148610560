import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

function Resume() {
  const [user] = useRecoilState(userSelector);

  return (
    <div>
      {user.resumes?.file.length === 0 && user.resumes?.url.length === 0 ? (
        <div className='text-[13px]'>履歴書・職務経歴書が入力されていません。</div>
      ) : (
        <div className='text-[13px]'>
          <div className='mb-6'>
            {user.resumes?.file?.map((item, index) => (
              <div key={index}>
                <div className='flex items-center'>
                  <p className='mr-2 font-bold'>{item.file_name}</p>
                  <span className='text-[12px] text-gray-500'>
                    {new Date(item.upload_at).toLocaleString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div className='mb-2 mt-1 text-[12px] text-gray-500'>
                  {item.file_extension}ファイル&nbsp;
                  {item.file_size}
                </div>
              </div>
            ))}
          </div>

          {user.resumes?.url?.map((item, index) => (
            <a
              key={index}
              className='mb-2 flex cursor-pointer items-center'
              href={item.url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src='/images/link.svg'
                className='mr-2 h-5 w-5 rounded-full border bg-gray-100 p-[2px] hover:bg-white'
              ></img>
              <p className='mr-2 font-bold'>{item.url}</p>
              <p className='text-[12px] text-gray-500'>{item.upload_at}</p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default Resume;
