import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import JobListItem from "@/components/layouts/top/JobListItem";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { JobList } from "@/types/job";
import { apiGet } from "@/utils/api";

function JobLists() {
  const { error } = useToast();
  const [key] = useRecoilState(locationState);
  const [jobs, setJobs] = useState<JobList[]>([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await apiGet(key.tab === "side-job" ? "/api/offers?type=1" : "/api/offers?type=2");
      if ("data" in res) {
        setJobs(res.data as JobList[]);
      } else {
        error(res.message);
      }
    };
    fetchJobs();
  }, [key.tab]);

  return (
    <div className='flex justify-center bg-blue-100'>
      <div className='py-[40px] sm:w-screen md:w-[1100px]'>
        <div className='p-4 text-center text-[24px]'>おすすめ求人</div>
        <div className='mt-6 grid grid-cols-12'>
          {jobs.map((job) => (
            <div key={job.id} className='flex justify-center sm:col-span-12 md:col-span-4'>
              <JobListItem {...(job as JobList)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobLists;
