import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

function DesireJob() {
  const [user] = useRecoilState(userSelector);

  return (
    <div>
      {user.user_desired_job_types.length === 0 ? (
        <div className='text-[13px]'>希望職種が入力されていません。</div>
      ) : (
        <div className='text-[13px]'>
          {user.user_desired_job_types.map((item, index) => (
            <p className='pb-1' key={index}>
              {item.job_type_name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default DesireJob;
