import { selector } from "recoil";

import { masterState } from "@/stores/atoms/masterAtom";
import { Masters } from "@/types/master";

export const masterSelector = selector<Masters>({
  key: "masterSelector",
  get: ({ get }) => {
    const masters: Masters = get(masterState);

    return masters;
  },
  set: ({ set }, newValue) => {
    set(masterState, newValue);
  },
});
