import { ReactNode, useState } from "react";

interface Props {
  value: number;
  select: (value: number[]) => void;
  values: number[];
  isSelected?: boolean;
  children: ReactNode;
  isDisabled: boolean;
}

export const MultiSelectButton = (props: Props) => {
  const { value, select, isSelected, children, values, isDisabled } = props;
  const [localSelected, setLocalSelected] = useState(isSelected || false);

  const handleButtonClick = () => {
    if (isDisabled && values.slice(-1)[0] === value && !isSelected) {
      select([values[0]]);
      setLocalSelected(true);
      return;
    }
    const selectValues = isSelected ? values.filter((value) => !values.includes(value)) : values;
    select(selectValues);
    setLocalSelected(!isSelected);
  };

  return (
    <button
      disabled={isDisabled && !localSelected}
      className={`${
        localSelected ? "bg-blue-800 text-white" : "bg-white text-blue-800"
      } flex h-10 justify-center rounded border border-blue-800 pb-2.5 pt-2.5 text-center text-sm font-bold sm:w-[145px] md:w-[120px]`}
      onClick={handleButtonClick}
    >
      {children}
    </button>
  );
};

export default MultiSelectButton;
