import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditDesireJob from "@/components/layouts/profile/EditDesireJob";

function DesireJobPage() {
  return (
    <div className='flex min-h-screen flex-col bg-blue-100'>
      <div className='mx-auto flex max-w-md  flex-col items-center  px-2'>
        <div className='text-black relative mt-8 flex justify-center rounded bg-white px-6  py-8  sm:w-[375px] md:w-[760px]'>
          <ProgressBar progress={18} />
          <EditDesireJob isProfile={false} />
        </div>
      </div>
    </div>
  );
}

export default DesireJobPage;
