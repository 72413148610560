function Lp04() {
  return (
    <iframe
      className='h-screen w-screen'
      src='/lp/lp04/index.html'
      title='副業・転職マッチングサービス kasooku（カソーク）'
    ></iframe>
  );
}

export default Lp04;
