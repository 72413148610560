import { useLocation, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import EditButtonGroup from "@/components/layouts/parts/EditButtonGroup";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiPatch } from "@/utils/api";

interface CurrentCompany {
  [key: string]: string | number;
}

function EditCurrentCompany(props: {
  setIsAddCurrentCompany: (arg0: boolean) => void;
  bgColor: string;
  isProfile: boolean;
}) {
  useControlBlowserBack();
  const location = useLocation();
  const [user, setUser] = useRecoilState(userSelector);

  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    current_company: yup.string().required("現職企業名は必須入力項目です").max(255, "255文字以内で入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ current_company: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      current_company: user.current_company,
    },
  });

  const onSubmit: SubmitHandler<CurrentCompany> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 6;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && location.pathname !== "/profile") {
      setKey((prevValue) => ({ ...prevValue, path: "/register/current-job" }));
      navigate("/register/current-job");
      return;
    }
    if (res.code === 200 && location.pathname === "/profile") {
      setUser((prevState: User) => Object.assign({}, prevState, params));
      props.setIsAddCurrentCompany(false);
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  const handleCancel = () => {
    props.setIsAddCurrentCompany(false);
  };

  return (
    <div className={`form-group mb-6 p-6 ${props.bgColor}`}>
      <Loading />
      <div className={`max-w-[${props.isProfile ? 729 : 320}px]`}>
        <div className='mb-6 mr-1'>
          {!props.isProfile && (
            <label htmlFor='current-company' className='text-[14px] font-bold'>
              現職企業名
            </label>
          )}
          <input
            type='text'
            className='border-grey-light block w-full rounded-md border p-3'
            {...register("current_company")}
          />
          <span className='absolute py-1 text-xs text-red-500'>{errors.current_company?.message}</span>
          {!props.isProfile && <p className='mt-6 text-[12px] text-gray-500'>正式名称でご記入ください</p>}
        </div>
        {props.isProfile ? (
          <div className='flex justify-end'>
            <EditButtonGroup submit={handleSubmit(onSubmit)} cancel={handleCancel} isSubmit={true} />
          </div>
        ) : (
          <button
            type='submit'
            className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
            onClick={handleSubmit(onSubmit)}
          >
            次へ
          </button>
        )}
      </div>
    </div>
  );
}

export default EditCurrentCompany;
