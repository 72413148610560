import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { Identification, User } from "@/types/user";
import { apiPost } from "@/utils/api";

interface VerifyCode {
  [key: string]: string;
}

function RegisterPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error, success } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [user] = useRecoilState(userSelector);

  const [verifyCode, setVerifyCode] = useState<VerifyCode>({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  });

  const reSend = async () => {
    if (user.first_name === "") {
      error("認証コードの送信に失敗しました");
      setTimeout(() => {
        navigate("/register");
      }, 1000);
      return;
    }

    const params: Identification = {
      first_name: user.first_name,
      last_name: user.last_name,
      first_name_kana: user.first_name_kana,
      last_name_kana: user.last_name_kana,
      phone_number: user.phone_number,
    };

    const res = await apiPost("/api/register", params);
    if (res.success) {
      success("認証コードを再送信しました");
      return;
    }

    res.code === 422 && res.errors && error("認証コードの送信に失敗しました");
    setTimeout(() => {
      navigate("/register");
    }, 1000);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const value = event.target.value;
    if (value === "") {
      setVerifyCode({ ...verifyCode, [key]: value });
      return;
    }

    if (value.match(/^[0-9]+$/)) {
      setVerifyCode({ ...verifyCode, [key]: value });
      setTimeout(() => {
        const nextKey = key.replace("code", "");
        const nextInput = document.getElementById(`code${parseInt(nextKey) + 1}`);
        nextInput?.focus();
      }, 100);
    }
  }

  const sendSms = async (code: string) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const params = {
      phone_number: user.phone_number,
      verification_code: code,
    };

    const res = await apiPost<undefined, { verification_code: string[] }>("/api/sms/verify", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/email" }));
      navigate("/register/email");
      return;
    }

    if (res.code === 422 && res.errors) {
      const message = res.errors["verification_code"][0];
      switch (message) {
        case "すでに認証済みです":
          error(message);
          setKey((prevValue) => ({ ...prevValue, path: "/register/email" }));
          navigate("/register/email");
          break;
        case "認証コードの有効期限切れです":
          error(message);
          setKey((prevValue) => ({ ...prevValue, path: "/register" }));
          navigate("/register");
          break;
        case "認証コードが一致しません":
          error(message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          break;
      }
    }
  };

  useEffect(() => {
    const key = Object.keys(verifyCode).find((key) => {
      return verifyCode[key] == "";
    });
    if (key === "code1" || key !== undefined) {
      return;
    }

    document.getElementById("code6")?.blur();
    const code = Object.values(verifyCode).join("");
    sendSms(code);
  }, [verifyCode]);

  return (
    <div className='flex min-h-screen w-full flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[384px] sm:w-full md:top-[111px] md:h-[384px] md:w-[760px]'>
          <ProgressBar progress={2} />
          <div className='absolute w-[336px] text-[13px] sm:top-[110px] md:top-[90px]'>
            <h1 className='mb-8 text-center text-[20px] font-bold'>SMSに認証コードを送信しました</h1>
            <h2 className='mb-8 text-center text-[14px]'>
              メッセージに記載された
              <br />
              6桁の数字を入力してください。
            </h2>
            <div className='form-group mb-4 ml-8 mr-8'>
              <div className='mb-2 mr-1'>
                <label htmlFor='first-name' className='text-[13px] font-bold'>
                  認証コード
                </label>
                <div className='flex'>
                  <input
                    autoFocus
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    id='code1'
                    value={verifyCode["code1"]}
                    onChange={(event) => handleInputChange(event, "code1")}
                  />
                  <input
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    pattern='^[0-9]+$'
                    id='code2'
                    onChange={(event) => handleInputChange(event, "code2")}
                  />
                  <input
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    pattern='^[0-9]+$'
                    id='code3'
                    onChange={(event) => handleInputChange(event, "code3")}
                  />
                  <input
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    pattern='^[0-9]+$'
                    id='code4'
                    onChange={(event) => handleInputChange(event, "code4")}
                  />
                  <input
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    pattern='^[0-9]+$'
                    id='code5'
                    onChange={(event) => handleInputChange(event, "code5")}
                  />
                  <input
                    type='number'
                    className='border-grey-light ml-0.5 mr-0.5 block w-full rounded border p-3 text-center outline-1 [appearance:textfield] focus:bg-blue-200 focus:outline focus:outline-blue-800 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                    maxLength={1}
                    pattern='^[0-9]+$'
                    id='code6'
                    onChange={(event) => handleInputChange(event, "code6")}
                  />
                </div>
              </div>
            </div>
            <div className='text-grey-dark mb-8 mt-6 text-center text-[14px] text-blue-800'>
              <button type='submit' className='no-underline' onClick={reSend}>
                認証コードを再送信する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
