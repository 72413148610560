import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import EditButtonGroup from "../parts/EditButtonGroup";
import SelectBox from "../parts/SelectBox";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { masterState } from "@/stores/atoms/masterAtom";
import { Master } from "@/types/master";
import { User } from "@/types/user";
import { ApiResponse, apiGet, apiPatch } from "@/utils/api";

interface CurrentJob {
  [key: string]: string | number;
}

interface Props {
  user?: User;
  profileSubmit?: () => void;
  cancel?: () => void;
  isProfile: boolean;
}

export const EditCurrentJob = (props: Props) => {
  useControlBlowserBack();
  const { user, isProfile, profileSubmit, cancel } = props;
  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [master, setMaster] = useRecoilState(masterState);
  const [jobs, setJobs] = useState<Master[] | undefined>(master.jobs);

  const schema = yup.object({
    current_job: yup.string().required("現在の職種は必須入力項目です"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{ current_job: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      current_job: user?.current_job?.toString(),
    },
  });
  const data = jobs && jobs.find((v) => v.id === Number(watch().current_job))?.name;

  const onSubmit: SubmitHandler<CurrentJob> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));

    params.register_step = 7;
    const res = await apiPatch("/api/register", params);

    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && !isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/annual-income" }));
      navigate("/register/annual-income");
    }
    if (res.code === 200 && profileSubmit) {
      profileSubmit();
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  useEffect(() => {
    if (jobs && jobs.length > 0) {
      return;
    }

    const fetchJobs = async () => {
      try {
        const res: ApiResponse<Master[]> = await apiGet<Master[]>("/api/job-types");
        if (res.success) {
          if ("data" in res) {
            setMaster({ jobs: res.data });
            setJobs(res.data);
          }
        } else {
          console.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchJobs();
  }, []);

  return (
    <div
      className={` ${
        isProfile ? "rounded-[6px] bg-blue-50 p-[24px]" : "absolute w-[336px] sm:top-[110px] md:top-[90px]"
      }`}
    >
      <Loading />
      <div className='flex-col gap-5'>
        <div className='mb-[10px] flex flex-col gap-1'>
          {!isProfile && (
            <label htmlFor='currentJob' className='text-[14px] font-bold'>
              現在の職種
            </label>
          )}
          <SelectBox
            data={data ?? ""}
            errors={errors.current_job}
            options={
              jobs
                ? jobs.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }))
                : [
                    {
                      id: 0,
                      name: "",
                    },
                  ]
            }
            select={(value: string | number) => {
              setValue("current_job", value.toString());
            }}
            {...register("current_job")}
          />
        </div>
        {isProfile ? (
          <div className='flex items-center justify-end'>
            <EditButtonGroup submit={handleSubmit(onSubmit)} cancel={cancel} isSubmit={data !== ""} />
          </div>
        ) : (
          <button
            type='submit'
            className='my-1 w-full rounded-full bg-blue-700 py-3 text-center text-white hover:bg-blue-500 focus:outline-none'
            onClick={handleSubmit(onSubmit)}
          >
            次へ
          </button>
        )}
      </div>
    </div>
  );
};

export default EditCurrentJob;
