import { useEffect, useState } from "react";

const useMediaQuery = () => {
  const [isSm, setIsSm] = useState<boolean>();
  const [isMd, setIsMd] = useState<boolean>();

  useEffect(() => {
    const checkMediaQuery = () => {
      const isSmMatch = window.matchMedia("(max-width: 767px)").matches;
      const isMdMatch = window.matchMedia("(min-width: 768px) and (max-width: 991px)").matches;

      setIsSm(isSmMatch);
      setIsMd(isMdMatch);
    };

    checkMediaQuery();

    window.addEventListener("resize", checkMediaQuery);

    return () => {
      window.removeEventListener("resize", checkMediaQuery);
    };
  }, []);

  return isSm;
};

export default useMediaQuery;
