import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function ToriatsukaiPage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0 '>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <p className='mb-2 text-[16px]'>求人者・求職者の皆様へ</p>
        <p className='w-full text-center text-[28px] font-bold'>取り扱い職種の範囲等の明示</p>
        <p className='my-8 text-[16px]'>
          職業安定法第32の13、職業安定法施行規則第24条の5により、以下項目を明示します。
        </p>

        {/* 1. 事業所の名称等 */}
        <div className='container mx-auto p-4'>
          <h2 className='text-[16px] font-bold'>1. 事業所の名称等</h2>
          <ul className='mt-8 p-2 text-[16px]'>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>事業所の名称</p>
              <p className='md:w-4/6'>カソーク株式会社 本社事務所</p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>所在地</p>
              <p className='md:w-4/6'>東京都渋谷区渋谷一丁目2番5号MFPRビル10階</p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>許可番号</p>
              <p className='md:w-4/6'>13-ユ-315108</p>
            </li>
          </ul>

          {/* 2. 法第32条の13及び則第24条の５に規定する明示事項 */}
          <h2 className='mt-8 text-[18px] font-bold'>2. 法第32条の13及び則第24条の５に規定する明示事項</h2>
          <ul className='mt-8 p-2 text-[16px]'>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>(1) 取扱職種の範囲等</p>
              <p className='md:w-4/6'>
                取扱職種：全職種
                <br />
                地域：国内
              </p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>(2) 手数料に関する事項</p>
              <p className='md:w-4/6'>「手数料表」のとおり</p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>(3) 苦情の処理に関する事項</p>
              <p className='md:w-4/6'>
                苦情申出先：カソーク株式会社
                <br />
                職業紹介責任者：前中貴斗<br />
                連絡先：03-6822-9106
              </p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>(4) 個人情報の取扱いに関する事項</p>
              <p className='md:w-4/6'>「個人情報適正管理規程」のとおり</p>
            </li>
            <li className='mb-4 border-b border-gray-200 md:flex'>
              <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6'>(5) 返戻金制度に関する事項</p>
              <p className='md:w-4/6'>
                返戻金制度はございません。
                <br />
                ただし、求人者との個別の契約において、異なる条件の取り決めを行う場合があります。
              </p>
            </li>
          </ul>

          {/* 【一般登録型】 手数料表 */}
          <h2 className='mt-8 text-[18px] font-bold'>【一般登録型】 手数料表</h2>
          <table className='mt-4 w-full border-collapse border text-[16px]'>
            <thead>
              <tr className='border'>
                <th className='w-1/3 border p-2'>サービスの種類及び内容</th>
                <th className='w-2/3 border p-2'>手数料の額及び負担者</th>
              </tr>
            </thead>
            <tbody>
              <tr className='border'>
                <td className='border p-2'>求人受理時の事務費用</td>
                <td className='border p-2'>
                  <p className='w-full items-end justify-end text-right'>
                    <span className='border-b border-gray-700'>0円</span>
                  </p>
                  手数料負担者は求人者とします。
                </td>
              </tr>
              <tr className='border'>
                <td className='h-full items-start justify-start border p-2 '>
                  <p className=''>
                    求人受理後、求人者に求職者を紹介するサービス
                    <br />
                    【職業紹介サービス】
                  </p>
                  <p className='sm:mt-96 md:mt-60 '>
                    求人の充足に向けた求人者に対する専門的な相談・助言サービス
                    <br /> 【職業紹介の付加サービス】
                    <br />
                    <span className='text-[11px]'>
                      ＊上記職業紹介サービスに加えて、より専門的な相談・助言の付加サービスを行う場合
                    </span>
                  </p>
                </td>
                <td className='border p-2'>
                  成功報酬 <br />
                  （期間の定めのない雇用契約の紹介の場合）
                  <br />
                  当該求職者の就職後１年間に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <p className='flex w-full '>
                    <p className='w-full text-right'>
                      <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                    </p>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> （期間の定めのある雇用契約の紹介の場合）
                  当該求職者の就職後、雇用契約期間中（雇用期間が１年を超える場合は最大１年間分）に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <p className='w-full text-right'>
                    <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> 手数料負担者は求人者とします。
                  <p className='mt-14'>
                    成功報酬
                    <br />
                    当該求職者の就職後１年間に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                    <p className='flex w-full '>
                      <p className='w-full text-right'>
                        <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                      </p>
                    </p>
                    上記のうちどちらか高い方とする。
                    <br />
                    手数料負担者は求人者とします。
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <p className='flex w-full text-[14px]'>
              <p className='w-full text-right'>
                <span className='w-fit'>上記手数料には、消費税は含まれておりません。別途加算となります。</span>
              </p>
            </p>
          </p>

          {/* 【サーチ／スカウト型】 手数料表 */}
          <h2 className='mt-8 text-[18px] font-bold'>【サーチ／スカウト型】 手数料表</h2>
          <table className='mt-4 w-full border-collapse border text-[16px]'>
            <thead>
              <tr className='border'>
                <th className='w-1/3 border p-2'>サービスの種類及び内容</th>
                <th className='w-2/3 border p-2'>手数料の額及び負担者</th>
              </tr>
            </thead>
            <tbody>
              <tr className='border'>
                <td className='border p-2'>求人受理時の事務費用</td>
                <td className='border p-2'>
                  <p className='w-full items-end justify-end text-right'>
                    <span className='border-b border-gray-700'>0円</span>
                  </p>
                  手数料負担者は求人者とします。
                </td>
              </tr>
              <tr className='border'>
                <td className='border p-2'>特定の条件による特別な求職者の開拓やそのための調査・探索</td>
                <td className='border p-2'>
                  <p className='flex w-full '>
                    <span className='w-2/3'>着手金</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>100,000円</span>
                    </p>
                  </p>
                  <p className='flex w-full '>
                    <span className='w-2/3'>活動１日あたり</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>50,000円</span>
                    </p>
                  </p>
                  <p className='flex w-full '>
                    <span className='w-2/3'>(または、活動１時間あたり)</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>5,000円</span>
                    </p>
                  </p>
                  成功報酬 <br />
                  （期間の定めのない雇用契約の紹介の場合）
                  <br />
                  当該求職者の就職後１年間に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <p className='flex w-full '>
                    <p className='w-full text-right'>
                      <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                    </p>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> （期間の定めのある雇用契約の紹介の場合）
                  当該求職者の就職後、雇用契約期間中（雇用期間が１年を超える場合は最大１年間分）に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <p className='w-full text-right'>
                    <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> 手数料負担者は求人者とします。
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <p className='flex w-full text-[14px]'>
              <p className='w-full text-right'>
                <span className='w-fit'>上記手数料には、消費税は含まれておりません。別途加算となります。</span>
              </p>
            </p>
          </p>

          {/* 【再就職支援型】 手数料表 */}
          <h2 className='mt-8 text-[18px] font-bold'>【再就職支援型】 手数料表</h2>
          <table className='mt-4 w-full border-collapse border text-[16px]'>
            <thead>
              <tr className='border'>
                <th className='w-1/3 border p-2'>サービスの種類及び内容</th>
                <th className='w-2/3 border p-2'>手数料の額及び負担者</th>
              </tr>
            </thead>
            <tbody>
              <tr className='border'>
                <td className='border p-2'>就職を容易にするための求職者に対する専門的な相談・助言</td>
                <td className='border p-2'>
                  <p className='flex w-full '>
                    <span className='w-2/3'>着手金</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>100,000円</span>
                    </p>
                  </p>
                  <p className='flex w-full '>
                    <span className='w-2/3'>相談・助言終了時</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>30,000円</span>
                    </p>
                  </p>
                  <p className='flex w-full '>
                    <span className='w-2/3'>成功報酬</span>
                    <p className='w-1/3 text-right'>
                      <span className='w-fit border-b border-gray-700'>50,000円</span>
                    </p>
                  </p>
                  手数料負担者は関係雇用主とします。
                </td>
              </tr>
              <tr className='border'>
                <td className='border p-2'>
                  求人受理後、求人者に求職者を紹介するサービス <br />
                  【職業紹介サービス】
                </td>
                <td className='border p-2'>
                  成功報酬 <br />
                  （期間の定めのない雇用契約の紹介の場合）
                  職業紹介が成功した場合において、当該求職者の就職後１年間に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <br />
                  <p className='flex w-full '>
                    <p className='w-full text-right'>
                      <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                    </p>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> （期間の定めのある雇用契約の紹介の場合）
                  職業紹介が成功した場合において、当該求職者の就職後、雇用契約期間中（雇用期間が１年を超える場合は最大１年間分）に支払われる賃金（内定書や労働条件通知書等に記載されている額）の
                  <p className='w-full text-right'>
                    <span className='w-fit border-b border-gray-700'>100%（または500,000円)</span>
                  </p>
                  上記のうちどちらか高い方とする。
                  <br /> 手数料負担者は求人者とします。
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <p className='flex w-full text-[14px]'>
              <p className='w-full text-right'>
                <span className='w-fit'>上記手数料には、消費税は含まれておりません。別途加算となります。</span>
              </p>
            </p>
          </p>

          {/* 個人情報適正管理規程 */}
          <h2 className='mt-8 text-[18px] font-bold'>個人情報適正管理規程</h2>
          <ul className='mt-4 text-[16px]'>
            <li className='mb-2 flex w-full'>
              <span className='mr-4'>1.</span>
              <span>
                個人情報を取り扱う事業所内の職員の範囲は、職業紹介事業担当者とする。個人情報取扱責任者は職業紹介責任者
                前中貴斗とする。
              </span>
            </li>
            <li className='mb-2 flex w-full'>
              <span className='mr-4'>2.</span>
              <span>
                職業紹介責任者は、個人情報を取り扱う１に記載する事業所内の職員に対し、個人情報取扱いに関する教育・指導を年１回実施することとする。また、職業紹介責任者は、少なくとも５年に１回は職業紹介責任者講習会を受講するものとする。
              </span>
            </li>
            <li className='mb-2 flex w-full'>
              <span className='mr-4'>3.</span>
              <span>
                取扱者は、個人の情報に関して、当該情報に係る本人から情報の開示請求があった場合は、その請求に基づき本人が有する資格や職業経験等客観的事実に基づく情報の開示を遅滞なく行うものとする。さらに、これに基づき訂正（削除を含む。以下同じ。）の請求があったときは、当該請求が客観的事実に合致するときは、遅滞なく訂正を行うものとする。また、個人情報の開示又は訂正に係る取扱いについて、職業紹介責任者は求職者等への周知に努めることとする。
              </span>
            </li>
            <li className='mb-2 flex w-full'>
              <span className='mr-4'>4.</span>
              <span>
                求職者等の個人情報に関して、当該情報に係る本人からの苦情の申出があった場合については、苦情処理担当者は誠意を持って適切な処理をすることとする。なお、個人情報の取扱いに係る苦情処理の担当者は、職業紹介責任者
                前中貴斗とする。
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ToriatsukaiPage;
