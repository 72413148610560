interface Props {
  submit: () => void;
  cancel?: () => void;
  isSubmit?: boolean;
}
export const EditButtonGroup = (props: Props) => {
  const { cancel, submit, isSubmit = true } = props;
  return (
    <div className='flex gap-[16px] text-[13px]'>
      <button
        type='reset'
        className='my-1 h-[40px] w-[144px] py-3 text-center font-bold leading-5 text-blue-700 focus:outline-none'
        onClick={cancel}
      >
        キャンセル
      </button>
      <button
        type='submit'
        className={`my-1 h-[40px] w-[144px] rounded-[4px] bg-blue-700 py-2 text-center font-bold leading-5 text-white focus:outline-none ${
          !isSubmit ? "cursor-not-allowed bg-black-300" : "hover:bg-blue-500"
        }`}
        onClick={submit}
      >
        保存する
      </button>
    </div>
  );
};

export default EditButtonGroup;
