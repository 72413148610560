import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function CompanyPage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0'>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <p className='text-[28px] font-bold'>特定商取引法に基づく表記</p>
        <ul className='mt-8 p-2'>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>販売事業者名</p>
            <p className='md:w-4/6'>カソーク株式会社</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>運営責任者名</p>
            <p className='md:w-4/6'>前中貴斗</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>本社所在地</p>
            <p className='md:w-4/6'>東京都渋谷区渋谷1-2-5 MFPR渋谷ビル10F</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>電話番号</p>
            <p className='md:w-4/6'>03-6822-9106 (営業時間 10:00〜18:00)</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>問い合わせ先メールアドレス</p>
            <p className='md:w-4/6'> info@kasooku.jp</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>料金</p>
            <p className='md:w-4/6'> 当社サイト・アプリ上に表示するKasookuサービスの利用額</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>商品代金以外の必要料金</p>
            <p className='md:w-4/6'>
              消費税、サイト閲覧、コンテンツのダウンロードお問い合わせなどの際の電子メールの送受信時などの所定の通信料等
            </p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>お支払い方法</p>
            <p className='md:w-4/6'>クレジットカード決済</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>支払い時期 </p>
            <p className='md:w-4/6'>クレジットカード決済：初回申し込み時、次回以降は初回申し込み日から1ヶ月毎に請求</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>商品の引渡し時期</p>
            <p className='md:w-4/6'>当社所定の手続き終了後、直ちにご利用いただけます</p>
          </li>
          <li className='mb-4 border-b border-gray-200  md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>返品・キャンセル・解約</p>
            <p className='md:w-4/6'>
              商品特性上、返金・キャンセルについてはお受けできません。
              解約につきましては、期間満了後の解約手続きはマイページよりいつでも可能です。
              期間満了までサービスを提供し、翌月以降サービス提供及びご請求をお止めします。
            </p>
          </li>
          <li className='mb-4 md:flex'>
            <p className='mr-4 flex items-center font-bold sm:pb-4 md:w-2/6 '>PC・スマートフォンの動作環境</p>
            <p className='md:w-4/6'>
              サービスをご利用いただくために必要な動作環境は、サービスのサポートページに表示してます。
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CompanyPage;
