import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Masters } from "@/types/master";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const masterState = atom<Masters>({
  key: "master",
  default: {
    jobs: [],
    industries: [],
  },
  effects_UNSTABLE: [persistAtom],
});
