import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

const useControlBlowserBack = () => {
  const [key] = useRecoilState(locationState);
  const location = useLocation();
  useEffect(() => {
    if (key.path !== "" && location.pathname !== key.path) {
      const path = location.pathname === '/profile' ? 'profile' : key.path;
      window.history.replaceState(null, '', path);
    }
  }, []);
};
export default useControlBlowserBack;
