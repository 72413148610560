import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "react-loading";
import { useRecoilState } from "recoil";

import CareerChange from "@/components/layouts/jobs/CareerChange";
import CareerChangeTab from "@/components/layouts/jobs/CareerChangeTab";
import SideJob from "@/components/layouts/jobs/SideJob";
import SideJobTab from "@/components/layouts/jobs/SideJobTab";
import Top from "@/components/layouts/jobs/Top";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { JobDetail } from "@/types/job";
import { apiGet } from "@/utils/api";

function JobsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setActiveTab] = useState<number>(1);
  const { error } = useToast();
  const [job, setJob] = useState<JobDetail>({} as JobDetail);
  const [key, setKey] = useRecoilState(locationState);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    const fetchJobs = async () => {
      const query = token ? "?token=" + token : "";
      const res = await apiGet("/api/offers/" + id + query);
      res.code === 200 && "data" in res && setJob(res.data as JobDetail);
      if (res.code !== 200) {
        navigate("/");
        error("求人詳細の取得に失敗しました。");
      }
    };
    fetchJobs();
  }, [key.tab]);

  useEffect(() => {
    if (!job.id || job.id === undefined) return setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    setKey((prevValue) => ({ ...prevValue, path: "jobs/" + job.id, component: "detail" }));
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
  }, [job.id]);

  return (
    <div className='bg-blue-100 md:py-10'>
      {job.id ? (
        <div className='m-auto mb-8 rounded-md border-gray-200 bg-white font-sans sm:w-full sm:border-t md:w-[746px] md:border'>
          <div className='w-full'>
            <Top job={job} />
            {key.tab === "side-job" ? (
              <div>
                <SideJobTab setActiveTab={setActiveTab} /> <SideJob job={job} />
              </div>
            ) : (
              <div>
                <CareerChangeTab setActiveTab={setActiveTab} /> <CareerChange job={job} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='min-h-screen'>
          <Loading />
        </div>
      )}
    </div>
  );
}

export default JobsPage;
