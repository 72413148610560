import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userState } from "@/stores/atoms/userAtom";
import { apiPost } from "@/utils/api";

interface Email {
  [key: string]: string | number;
}

const ChangeEmail = () => {
  const isMobile = useMediaQuery();
  const { success } = useToast();
  const [inputText, setInputText] = useState("変更する");
  const [isComplete, setIsComplete] = useState(false);
  const [, setKey] = useRecoilState(locationState);
  const [user] = useRecoilState(userState);

  const schema = yup.object({
    new_email: yup
      .string()
      .required("メールアドレスは必須入力項目です")
      .email("有効なメールアドレスを入力してください")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "有効なメールアドレスを入力してください"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ new_email: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Email> = async (params) => {
    const res = await apiPost("/api/user/email/request", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
    if (res.code === 200) {
      setIsComplete(true);
      setValue("new_email", "");
      success("認証メールを送りました。24時間以内にクリックしてください。");
      setInputText("認証メールを再送する");
    }
  };

  useEffect(() => {
    setValue("new_email", user.email);
  }, [user.email, setValue]);

  return (
    <div className='pt-2 sm:w-full md:w-1/2'>
      <div>
        {!isComplete && <p className='mb-4 mt-2 text-[14px] text-gray-400'>変更後のアドレスを入力してください</p>}
        <div className='form-group mb-6'>
          <div className='mb-2 mr-1'>
            <label htmlFor='new_email' className='text-[13px] font-bold'>
              新しいメールアドレス
            </label>
            <input
              type='text'
              placeholder='sample@co.jp'
              className='border-grey-light mt-2 block w-full rounded border bg-gray-200 p-3'
              {...register("new_email")}
            />
            <span className='absolute py-1 text-xs text-red-500'>{errors.new_email?.message}</span>
          </div>
        </div>
        {!isComplete && <p className='mb-6 text-[12px] text-gray-400'>半角英数で入力してください</p>}
      </div>
      <div className={`w-full ${isMobile && "text-right"}`}>
        <button
          onClick={handleSubmit(onSubmit)}
          className='h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
        >
          {inputText}
        </button>
      </div>
    </div>
  );
};

export default ChangeEmail;
