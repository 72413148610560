import ReactLoading from "react-loading";
import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

const Loading = () => {
  const [key] = useRecoilState(locationState);

  if (key.isLoading) {
    return (
      <div className='fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-gray-800 bg-opacity-50 font-sans'>
        <div className='flex flex-col items-center'>
          <ReactLoading type='spin' color='#ffffff' height='100px' width='100px' className='z-50' />
        </div>
      </div>
    );
  } else {
    return <>{}</>;
  }
};

export default Loading;
