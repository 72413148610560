import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import SelectBox from "../parts/SelectBox";

import Loading from "@/components/layouts/common/Loading";
import Badge from "@/components/layouts/parts/Badge";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { Conditions } from "@/types/user";
import { apiPost } from "@/utils/api";

interface Option {
  id: number;
  name: string;
}

function EditCurrentCompany(props: {
  setIsAddConditions?: ((arg0: boolean) => void) | null;
  bgColor: string;
  isProfile: boolean;
  isPopUp: boolean;
  setIsConfirmConditionModalOpen?: ((arg0: boolean) => void) | null;
}) {
  const isMobile = useMediaQuery();
  useControlBlowserBack();
  const [user, setUser] = useRecoilState(userSelector);

  const navigate = useNavigate();
  const { error, success } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [conditions, setConditions] = useState<Conditions>({
    desired_working_hours: user.desired_working_hours,
    desired_working_time_zone: user.desired_working_time_zone,
    desired_unit_price: user.desired_unit_price,
  });

  const desired_working_hours_options: Option[] = [
    {
      id: 1,
      name: "1時間~5時間",
    },
    {
      id: 2,
      name: "5時間~10時間",
    },
    {
      id: 3,
      name: "10時間~20時間",
    },
    {
      id: 4,
      name: "20時間~30時間",
    },
    {
      id: 5,
      name: "30時間~40時間",
    },
  ];

  const desired_working_time_zone_options: Option[] = [
    {
      id: 1,
      name: "いつでも",
    },
    {
      id: 2,
      name: "平日（いつでも）",
    },
    {
      id: 3,
      name: "平日（9:00-19:00）",
    },
    {
      id: 4,
      name: "平日（9:00-19:00以外）",
    },
    {
      id: 5,
      name: "土・日のみ",
    },
  ];

  const desired_unit_price_options: Option[] = [
    {
      id: 1,
      name: "1,500円〜2,000円",
    },
    {
      id: 2,
      name: "2,000円〜3,000円",
    },
    {
      id: 3,
      name: "3,000円〜4,000円",
    },
    {
      id: 4,
      name: "4,000円〜5,000円",
    },
    {
      id: 5,
      name: "5,000円〜7,000円",
    },
    {
      id: 6,
      name: "7,000円〜10,000円",
    },
  ];

  const schema = yup.object({
    desired_working_hours: yup.number().required(),
    desired_working_time_zone: yup.number().required(),
    desired_unit_price: yup.number().required(),
  });

  const {
    setValue,
    formState: { errors },
    watch,
  } = useForm<{ desired_working_hours: number; desired_working_time_zone: number; desired_unit_price: number }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      desired_working_hours: user.desired_working_hours as number,
      desired_working_time_zone: user.desired_working_time_zone as number,
      desired_unit_price: user.desired_unit_price as number,
    },
  });

  const desired_working_hours_data =
    desired_working_hours_options &&
    desired_working_hours_options.find((v) => v.id === Number(watch().desired_working_hours))?.name;
  const desired_working_time_zone_data =
    desired_working_time_zone_options &&
    desired_working_time_zone_options.find((v) => v.id === Number(watch().desired_working_time_zone))?.name;
  const desired_unit_price_data =
    desired_unit_price_options &&
    desired_unit_price_options.find((v) => v.id === Number(watch().desired_unit_price))?.name;

  const handleSubmit = async () => {
    !props.isProfile && setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const params = Object.fromEntries(Object.entries(conditions).filter(([key, value]) => value !== null));

    // ポップアップ: 時刻記録
    if (props.isPopUp) {
      const formattedDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      params.last_displayed_at = formattedDate;
    }

    const res = await apiPost("/api/user/desired-condition", params);
    !props.isProfile && setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    res.code === 422 && res.errors && error("登録に失敗しました");

    // 新規登録
    if (!props.isProfile && !props.isPopUp) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/desired-industry" }));
      navigate("/register/desired-industry");
      return;
    }
    setUser((prevValue) => ({
      ...prevValue,
      desired_working_hours: params.desired_working_hours,
      desired_working_time_zone: params.desired_working_time_zone,
      desired_unit_price: params.desired_unit_price,
    }));
    // 更新時
    props.isProfile && success("希望稼働条件を保存しました");
    // ポップアップ時
    if (props.isPopUp) {
      props.setIsConfirmConditionModalOpen && props.setIsConfirmConditionModalOpen(false);
      window.location.reload();
    }
  };

  const handleOptionSelect = (optionId: unknown, fieldName: string) => {
    setConditions({ ...conditions, [fieldName]: optionId });
  };

  const isUpdateProfile = () => {
    // プロフィール: 選択し変更で更新
    return (
      props.isProfile &&
      !props.isPopUp &&
      (conditions.desired_unit_price !== user.desired_unit_price ||
        conditions.desired_working_hours !== user.desired_working_hours ||
        conditions.desired_working_time_zone !== user.desired_working_time_zone)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isUpdateProfile() && handleSubmit();
  }, [conditions]);

  return (
    <div className={`form-group p-8 pb-4 ${props.bgColor} w-full rounded-md`}>
      <Loading />
      <div className='mr-1'>
        {!props.isProfile && !props.isPopUp && <p className='mb-6 text-[14px] font-bold'>稼働条件</p>}
        {props.isProfile && !props.isPopUp && <p className='text-[14px]'>希望稼働条件</p>}
      </div>
      {!props.isProfile || isMobile || props.isPopUp ? (
        <div className='mb-8'>
          <div className={`${!props.isPopUp && "md:flex"}my-4`}>
            {isMobile && props.isPopUp && (
              <div className='relative left-[-50px] flex'>
                <p className='mr-4 text-[14px] font-bold text-gray-500'>稼働時間</p>
                <p className='text-[13px] text-blue-700'>{desired_working_hours_data}</p>
              </div>
            )}
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>稼働時間(週)</p>
              <Badge isDisplay={!user.desired_working_hours && props.isProfile} />
            </div>
            <SelectBox
              data={desired_working_hours_data ?? "選択してください。"}
              errors={errors.desired_working_hours}
              options={
                desired_working_hours_options
                  ? desired_working_hours_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_working_hours", value as number);
                handleOptionSelect(value, "desired_working_hours");
              }}
            />
          </div>
          <div className={`${!props.isPopUp && "md:flex"}my-4`}>
            {isMobile && props.isPopUp && (
              <div className='relative left-[-50px] mt-4 flex'>
                <p className='mr-4 text-[14px] font-bold text-gray-500'>稼働時間帯</p>
                <p className='text-[13px] text-blue-700'>{desired_working_time_zone_data}</p>
              </div>
            )}
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>稼働時間帯</p>
              <Badge isDisplay={!user.desired_working_time_zone && props.isProfile} />
            </div>
            <SelectBox
              data={desired_working_time_zone_data ?? "選択してください。"}
              errors={errors.desired_working_time_zone}
              options={
                desired_working_time_zone_options
                  ? desired_working_time_zone_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_working_time_zone", value as number);
                handleOptionSelect(value, "desired_working_time_zone");
              }}
            />
          </div>
          <div className={`${!props.isPopUp && "md:flex"}my-4`}>
            {isMobile && props.isPopUp && (
              <div className='relative left-[-50px] mt-4 flex'>
                <p className='mr-4 text-[14px] font-bold text-gray-500'>稼働単価</p>
                <p className='text-[13px] text-blue-700'>{desired_unit_price_data}</p>
              </div>
            )}
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>稼働単価</p>
              <Badge isDisplay={!user.desired_unit_price && props.isProfile} />
            </div>
            <SelectBox
              data={desired_unit_price_data ?? "選択してください。"}
              errors={errors.desired_unit_price}
              options={
                desired_unit_price_options
                  ? desired_unit_price_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_unit_price", value as number);
                handleOptionSelect(value, "desired_unit_price");
              }}
            />
          </div>
        </div>
      ) : (
        <div className='flex'>
          <div className='my-4 mr-4'>
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>稼働時間</p>
              <Badge isDisplay={!user.desired_working_hours && props.isProfile} />
            </div>
            <SelectBox
              data={desired_working_hours_data ?? "選択してください。"}
              errors={errors.desired_working_hours}
              options={
                desired_working_hours_options
                  ? desired_working_hours_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_working_hours", value as number);
                handleOptionSelect(value, "desired_working_hours");
              }}
            />
          </div>
          <div className='my-4 mr-4'>
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>稼働時間帯</p>
              <Badge isDisplay={!user.desired_working_time_zone && props.isProfile} />
            </div>
            <SelectBox
              data={desired_working_time_zone_data ?? "選択してください。"}
              errors={errors.desired_working_time_zone}
              options={
                desired_working_time_zone_options
                  ? desired_working_time_zone_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_working_time_zone", value as number);
                handleOptionSelect(value, "desired_working_time_zone");
              }}
            />
          </div>
          <div className='my-4'>
            <div className='flex'>
              <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>希望単価</p>
              <Badge isDisplay={!user.desired_unit_price && props.isProfile} />
            </div>
            <SelectBox
              data={desired_unit_price_data ?? "選択してください。"}
              errors={errors.desired_unit_price}
              options={
                desired_unit_price_options
                  ? desired_unit_price_options.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }))
                  : [
                      {
                        id: 0,
                        name: "",
                      },
                    ]
              }
              select={(value: unknown) => {
                setValue("desired_unit_price", value as number);
                handleOptionSelect(value, "desired_unit_price");
              }}
            />
          </div>
        </div>
      )}
      {!props.isProfile && !props.isPopUp && (
        <div className='mb-8 text-center'>
          <div className='w-full text-center sm:top-[350px] md:top-[300px]'>
            {desired_working_time_zone_data && desired_working_hours_data && desired_unit_price_data ? (
              <button
                type='submit'
                className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                onClick={handleSubmit}
              >
                次へ
              </button>
            ) : (
              <button
                disabled
                type='submit'
                className='w-full rounded-full bg-gray-300 py-3 text-center text-[13px] font-bold text-white sm:my-2 md:m-2'
              >
                次へ
              </button>
            )}
          </div>
        </div>
      )}

      {props.isPopUp && (
        <div className='relative flex gap-[16px] text-[13px] sm:left-[-12px] md:left-[-180px] '>
          <button
            type='reset'
            className='my-1 h-[40px] rounded-[4px] bg-blue-200 py-3 text-center font-bold leading-5 text-blue-700 hover:bg-blue-100 hover:text-blue-500 sm:w-[106px] md:w-[200px]'
            onClick={handleSubmit}
          >
            このままでよい
          </button>
          <button
            type='button'
            className={
              "my-1 mr-16 h-[40px] rounded-[4px] bg-blue-700 py-2 text-center font-bold leading-5 text-white hover:bg-blue-500 focus:outline-none sm:w-[106px] md:w-[200px]"
            }
            onClick={handleSubmit}
          >
            更新する
          </button>
        </div>
      )}
    </div>
  );
}

export default EditCurrentCompany;
