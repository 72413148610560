import { useRecoilState } from "recoil";

import EditConditions from "@/components/layouts/profile/EditConditions";
import useMediaQuery from "@/hooks/useMediaQuery";
import { userSelector } from "@/stores/selectors/userSelector";

type WithdrawModalProps = {
  setIsConfirmConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
interface Option {
  id: number;
  name: string;
}

const ConfirmConditionModal = (props: WithdrawModalProps) => {
  const isMobile = useMediaQuery();
  const [user] = useRecoilState(userSelector);
  const desired_working_hours_options: Option[] = [
    {
      id: 1,
      name: "1時間~5時間",
    },
    {
      id: 2,
      name: "5時間~10時間",
    },
    {
      id: 3,
      name: "10時間~20時間",
    },
    {
      id: 4,
      name: "20時間~30時間",
    },
    {
      id: 5,
      name: "30時間~40時間",
    },
  ];
  const desired_working_time_zone_options: Option[] = [
    {
      id: 1,
      name: "いつでも",
    },
    {
      id: 2,
      name: "平日（いつでも）",
    },
    {
      id: 3,
      name: "平日（9:00-19:00）",
    },
    {
      id: 4,
      name: "平日（9:00-19:00以外）",
    },
    {
      id: 5,
      name: "土・日のみ",
    },
  ];
  const desired_unit_price_options: Option[] = [
    {
      id: 1,
      name: "1,500円〜2,000円",
    },
    {
      id: 2,
      name: "2,000円〜3,000円",
    },
    {
      id: 3,
      name: "3,000円〜4,000円",
    },
    {
      id: 4,
      name: "4,000円〜5,000円",
    },
    {
      id: 5,
      name: "5,000円〜7,000円",
    },
    {
      id: 6,
      name: "7,000円〜10,000円",
    },
  ];
  const desired_unit_price = desired_unit_price_options.filter((item) => item.id === user.desired_unit_price)[0];
  const desired_working_hours = desired_working_hours_options.filter(
    (item) => item.id === user.desired_working_hours,
  )[0];
  const desired_working_time_zone = desired_working_time_zone_options.filter(
    (item) => item.id === user.desired_working_time_zone,
  )[0];
  return (
    <div>
      <div className='fixed left-0 top-0 z-40 block h-full w-full cursor-pointer bg-modal-overlay' />
      <div className='absolute left-1/2 z-50 h-fit -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white p-6 sm:top-1/2 sm:w-[95%] sm:px-4 md:top-1/2 md:w-[600px] md:px-8'>
        <div className='w-full'>
          <div className='flex text-center'>
            <p className='mb-4 text-[20px] font-bold'>条件のご確認をお願いいたします</p>
            <img src='/images/light.svg' className='h-6 w-6 pt-2'></img>
          </div>
        </div>
        <div className='my-2 w-full md:text-center'>
          <p className='text-[14px] text-gray-500'>現在の稼働状況について更新があれば、更新してください。</p>
        </div>
        <div className='grid grid-cols-12'>
          {!isMobile && (
            <div className='col-span-4 ml-4'>
              <div className='md:absolute md:top-[155px]'>
                <p className='text-[14px] font-bold text-gray-500'>稼働時間(週)</p>
                <p className='mt-1 text-[13px] text-blue-700'>
                  {desired_working_hours && "週に" + desired_working_hours.name}
                </p>
              </div>
              <div className='md:absolute md:top-[237px]'>
                <p className='text-[14px] font-bold text-gray-500'>稼働時間帯</p>
                <p className='mt-1 text-[13px] text-blue-700'>{desired_working_time_zone?.name}</p>
              </div>
              <div className='md:absolute md:top-[324px]'>
                <p className='text-[14px] font-bold text-gray-500'>希望単価</p>
                <p className='mt-1 text-[13px] text-blue-700'>{desired_unit_price?.name}</p>
              </div>
            </div>
          )}
          <div className='col-span-8 ml-4 flex w-screen'>
            <div>
              <img src='/images/arrow.svg' className='h-5 w-5 sm:mt-20 md:mt-16'></img>
              <img src='/images/arrow.svg' className='h-5 w-5 sm:mt-24 md:mt-14'></img>
              <img src='/images/arrow.svg' className='h-5 w-5 sm:mt-[105px] md:mt-16'></img>
            </div>
            <EditConditions
              bgColor={"bg-white"}
              isProfile={false}
              isPopUp={true}
              setIsConfirmConditionModalOpen={props.setIsConfirmConditionModalOpen}
            ></EditConditions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmConditionModal;
