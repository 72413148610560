import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { apiPatch, apiPost } from "@/utils/api";

const pushToDataLayer = (event: string, data: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: event,
      'jobchange': data,
    });
  }
};

interface PostalCode {
  [key: string]: string | number;
}

function PostalCodePage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [user] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    postal_code: yup
      .string()
      .required("郵便番号は必須入力項目です")
      .matches(/^\d{7}$/, "正しい形式で入力してください (例: 1234567)"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ postal_code: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PostalCode> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 10;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/current-industry" }));
      navigate("/register/current-industry");
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  const handleSkip = () => {
    setKey((prevValue) => ({ ...prevValue, path: "/register/current-industry" }));
    navigate("/register/current-industry");
  };

  const GTM_JOB_CHANGE_VALUES = [
    '',
    '3month',
    'unemployed',
    'ifgoodjob',
    'notnow',
    '6month',
  ];

  const getWillingnessJobChangeValue = () => {
    return GTM_JOB_CHANGE_VALUES[user.willingness_job_change as number];
  }

  useEffect(() => {
    pushToDataLayer('jobchange', getWillingnessJobChangeValue());
  }, []);

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[400px] sm:w-[375px] md:top-[111px] md:h-[320px] md:w-[760px]'>
          <ProgressBar progress={12} />
          <div className='absolute text-[13px] sm:top-[110px] sm:w-[336px] md:top-[90px] md:w-[500px]'>
            <div className='mb-8'>
              <p>
                <label htmlFor='birthday' className='text-[14px] font-bold'>
                  郵便番号
                </label>
              </p>
              <input
                type='text'
                className='h-10 w-32 rounded-md border border-gray-200 p-3'
                {...register("postal_code")}
              />
              <p className='mt-[4px] text-[13px] text-gray-500'>ハイフンなしで入力してください</p>
              <p className='p-1 text-xs text-red-500'>{errors.postal_code?.message}</p>
            </div>
            <div className='md:flex'>
              <button
                type='submit'
                className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                onClick={handleSubmit(onSubmit)}
              >
                次へ
              </button>
            </div>
            <div className='text-grey-dark mb-8 mt-6 text-center text-[14px] text-blue-800'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostalCodePage;
