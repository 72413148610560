import { useEffect } from "react";

import Apply from "@/components/layouts/jobs/Apply";
import { JobDetail } from "@/types/job";

function CareerChange(props: { job: JobDetail }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='rounded-md bg-white sm:p-4 md:p-8'>
      {/* 業務内容・責任範囲 */}
      <div className='mb-16' id='1'>
        <p className='my-2 text-[18px] font-bold'>業務内容・責任範囲</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.business_content_summary && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>具体的な業務内容</p>
              <div className='mb-10'>
                <span
                  className='mb-4'
                  dangerouslySetInnerHTML={{ __html: props.job.business_content_summary.replace(/\n/g, "<br />") }}
                />
              </div>
            </div>
          )}
          {props.job.business_content_detail && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>業務詳細</p>
              <span dangerouslySetInnerHTML={{ __html: props.job.business_content_detail.replace(/\n/g, "<br />") }} />
            </div>
          )}
          {props.job.area_of_responsibility && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>責任範囲</p>
              <span dangerouslySetInnerHTML={{ __html: props.job.area_of_responsibility.replace(/\n/g, "<br />") }} />
            </div>
          )}
        </div>
      </div>
      {/* 求めるスキル・経験 */}
      <div className='mb-16' id='2'>
        <p className='my-2 text-[18px] font-bold'>求めるスキル・経験</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.required_skills && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>必須スキル・経験</p>
              <ul className='ml-4 list-inside list-disc'>
                <span dangerouslySetInnerHTML={{ __html: props.job.required_skills.replace(/\n/g, "<br />") }} />
              </ul>
            </div>
          )}
          {props.job.welcome_skills && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>歓迎するスキル・経験</p>
              <ul className='ml-4 list-inside list-disc'>
                <span dangerouslySetInnerHTML={{ __html: props.job.welcome_skills.replace(/\n/g, "<br />") }} />
              </ul>
            </div>
          )}
        </div>
      </div>
      {/* 働く条件 */}
      <div className='mb-16' id='3'>
        <p className='my-2 text-[18px] font-bold'>働く条件</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.annual_income && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>年収</p>
              <p className='md:w-5/6'>{props.job.annual_income}</p>
            </div>
          )}
          {props.job.salary_system && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>給与体系</p>
              <div className='md:w-5/6'>
                {props.job.salary_system && (
                  <span className='flex'>
                    <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>
                    <span className='whitespace-nowrap'>賃金形態：</span>
                    <span dangerouslySetInnerHTML={{ __html: props.job.salary_system.replace(/\n/g, "<br />") }} />
                  </span>
                )}
              </div>
            </div>
          )}
          {props.job.salary_revision && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>給与改定</p>
              <p className='md:w-5/6'>{props.job.salary_revision}</p>
            </div>
          )}
          {props.job.bonus && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>賞与</p>
              <p className='md:w-5/6'>{props.job.bonus}</p>
            </div>
          )}
          {props.job.employment_status && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>雇用形態</p>
              <p className='md:w-5/6'>{props.job.employment_status}</p>
            </div>
          )}
          {(props.job.place_of_work || props.job.location) && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>勤務場所</p>
              <div>
                {props.job.place_of_work && (
                  <div>
                    <p>【勤務地詳細】</p>
                    <p className='mb-2'>{props.job.place_of_work}</p>
                  </div>
                )}
                {props.job.location && (
                  <div>
                    <p>【アクセス】</p>
                    <p>{props.job.location}</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {props.job.remote_work && (
            <div className='items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>リモートワーク</p>
              <p className='md:w-5/6'>{props.job.remote_work}</p>
            </div>
          )}
          {props.job.way_of_working && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'></p>
              <p className='md:w-5/6'>{props.job.way_of_working}</p>
            </div>
          )}
          {props.job.working_hours && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>勤務時間</p>
              <p className='md:w-5/6'>{props.job.working_hours}</p>
            </div>
          )}
          {props.job.working_time && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>勤務頻度</p>
              <span dangerouslySetInnerHTML={{ __html: props.job.working_time.replace(/\n/g, "<br />") }} />
            </div>
          )}
          {props.job.holiday && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>休暇・休日</p>
              <p className='md:w-5/6'>
                <span dangerouslySetInnerHTML={{ __html: props.job.holiday.replace(/\n/g, "<br />") }} />
              </p>
            </div>
          )}
          {props.job.treatment_benefits && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-[140px]'>待遇・福利厚生</p>
              <p className='md:w-5/6'>
                <span dangerouslySetInnerHTML={{ __html: props.job.treatment_benefits.replace(/\n/g, "<br />") }} />
              </p>
            </div>
          )}
        </div>
      </div>
      {/* キャリアステップ */}
      <div className='mb-16' id='4'>
        <p className='my-2 text-[18px] font-bold'>キャリアステップ</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.career_step && (
            <div>
              <span
                className='mb-1 flex'
                dangerouslySetInnerHTML={{ __html: props.job.career_step.replace(/\n/g, "<br />") }}
              />
            </div>
          )}
        </div>
      </div>
      {/* ビジョン・ミッション */}
      <div className='mb-16' id='5'>
        <p className='my-2 text-[18px] font-bold'>ビジョン・ミッション</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.company_vision && (
            <span className='mb-1 flex'>
              <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>Vision：
              <br />
              <div dangerouslySetInnerHTML={{ __html: props.job.company_vision.replace(/\n/g, "<br />") }} />
            </span>
          )}
        </div>
      </div>
      {/* 事業紹介 */}
      <div className='mb-16' id='6'>
        <p className='my-2 text-[18px] font-bold'>事業紹介</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.business && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: props.job.business.replace(/\n/g, "<br />") }} />
            </div>
          )}
        </div>
      </div>
      {/* 企業情報 */}
      <div id='7' className='mb-12'>
        <p className='my-2 text-[18px] font-bold'>企業情報</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.company_name && (
            <div>
              <p className='mb-4'>{props.job.company_name}</p>
              <a
                className='text-blue-500 hover:text-blue-300'
                href={props.job.company_url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {props.job.company_url}
              </a>
            </div>
          )}
        </div>
      </div>
      <Apply offer_id={props.job.id} title={props.job.title} is_applied={props.job.is_applied} />
    </div>
  );
}

export default CareerChange;
