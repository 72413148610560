function Lp03() {
  return (
    <iframe
      className='h-screen w-screen'
      src='/lp/lp03/index.html'
      title='[03]副業・転職マッチングサービス kasooku（カソーク'
    ></iframe>
  );
}

export default Lp03;
