import { useEffect } from "react";

import useMediaQuery from "@/hooks/useMediaQuery";

function useReloadOnResize() {
  const isSm = useMediaQuery();

  useEffect(() => {
    const onResize = () => {
      if (isSm) return;
      window.location.reload();
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [isSm]);
}

export default useReloadOnResize;
