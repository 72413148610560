import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import ChangeEmail from "@/components/layouts/settings/ChangeEmail";
import ChangePassword from "@/components/layouts/settings/ChangePassword";
import SideMenue from "@/components/layouts/settings/SideMenue";
import Withdraw from "@/components/layouts/settings/Withdraw";
import useAuth from "@/hooks/useAuth";
import useMediaQuery from "@/hooks/useMediaQuery";
import useScroll from "@/hooks/useScroll";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userState } from "@/stores/atoms/userAtom";
import { apiPost } from "@/utils/api";

function SettingPage() {
  const scroll = useScroll();
  const navigate = useNavigate();
  const { success } = useToast();
  const isSm = useMediaQuery();
  const [isFixed, setIsFixed] = useState(false);
  const [, setKey] = useRecoilState(locationState);
  const [user] = useRecoilState(userState);
  const { isReady } = useAuth();

  const sendToken = async (token: string) => {
    const res = await apiPost("/api/user/email/change", { token: token });
    if (res.code === 200) {
      navigate("/settings");
      success("メールアドレスの変更が完了しました");
    }
  };

  useEffect(() => {
    if (!isSm && scroll > 638) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, [scroll]);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, isLoading: false, path: "/settings", component: "settings" }));
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    token && sendToken(token);
  }, []);

  if (!isReady) return <></>;

  return (
    <div className='overflow-x-hidden'>
      <div className='flex justify-center bg-white'>
        <div className='my-16 flex md:w-[1000px]'>
          <div className={`${isFixed ? "fixed top-5 " : "relative"}`}>
            <SideMenue />
          </div>
          <div className='w-full'>
            <div
              className='w-full overflow-x-hidden pl-8 sm:pr-8 md:pr-0'
              style={{ marginLeft: isFixed ? "175px" : "0" }}
            >
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='change-email'>
                <p className='text-[16px]'>メールアドレス変更</p>
                <ChangeEmail></ChangeEmail>
              </div>
            </div>
            <div
              className='w-full overflow-x-hidden pl-8 sm:pr-8 md:pr-0'
              style={{ marginLeft: isFixed ? "175px" : "0" }}
            >
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='change-password'>
                <p className='text-[16px]'>パスワード変更</p>
                <ChangePassword></ChangePassword>
              </div>
            </div>
            <div
              className='w-full overflow-x-hidden pl-8 sm:pr-8 md:pr-0'
              style={{ marginLeft: isFixed ? "175px" : "0" }}
            >
              <div className='mb-8 border-b pb-8 pl-4 sm:w-screen sm:pr-4 md:w-full  md:pr-0' id='withdraw'>
                <p className='text-[16px]'>退会</p>
                <Withdraw></Withdraw>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingPage;
