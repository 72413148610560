import { User } from "@/types/user";
import { ApiResponse, apiGet } from "@/utils/api";

export const getMe = async () => {
  const res: ApiResponse<User> = await apiGet<User>("/api/user/me");
  if ("data" in res && res.success) {
    return res.data;
  } else {
    console.error(res.message);
  }
};
