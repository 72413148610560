import useAuth from "@/hooks/useAuth";
import useMediaQuery from "@/hooks/useMediaQuery";

function FooterPage() {
  const isMd = useMediaQuery();
  const { isReady } = useAuth();

  if (!isReady || location.pathname.startsWith("/tl/")) return <></>;

  return (
    <div className='relative border-t-4 border-blue-900 bg-white font-sans sm:py-8 md:h-[400px] md:p-8 md:px-16'>
      <div className='text-[14px] md:flex'>
        <div className='sm:flex sm:w-full sm:justify-center md:w-1/3'>
          <img src='/images/logo.svg ' alt='logo'></img>
        </div>
        {!isMd ? (
          <div className='flex w-full justify-end  font-bold'>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://recruitment.united.jp/' target='_blank' rel='noopener noreferrer'>
                会社概要
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='/term' target='_blank' rel='noopener noreferrer'>
                利用規約
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D' target='_blank' rel='noopener noreferrer'>
                プライバシーポリシー
              </a>
            </div>
            <div className='ml-5'>
              <a
                className='hover:text-gray-500'
                href='https://job.kasooku.jp/inq'
                target='_blank'
                rel='noopener noreferrer'
              >
                お問い合わせ
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxVtmoYKakDNmF45fRzXQib0%3D' target='_blank' rel='noopener noreferrer'>
                取り扱い職種の範囲等の明示
              </a>
            </div>
          </div>
        ) : (
          <div className='mt-8 w-full justify-end text-[13px]  font-bold'>
            <div className='flex w-full border-y border-gray-300'>
              <div className='w-1/2 py-2.5 text-center'>
                <a className='' href='/'>
                  ホーム
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='https://recruitment.united.jp/' target='_blank' rel='noopener noreferrer'>
                  会社概要
                </a>
              </div>
            </div>
            <div className='flex w-full '>
              <div className='w-1/2 py-2.5 text-center'>
                <a className='' href='/term' target='_blank' rel='noopener noreferrer'>
                  利用規約
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D' target='_blank' rel='noopener noreferrer'>
                  プライバシーポリシー
                </a>
              </div>
            </div>
            <div className='flex w-full border-gray-300'>
              <div className='w-1/2 border-b border-gray-300 py-2.5 text-center'>
                <a className='' href='https://job.kasooku.jp/inq' target='_blank' rel='noopener noreferrer'>
                  お問い合わせ
                </a>
              </div>
              <div className='w-1/2 border-b border-l border-gray-300 py-2.5 text-center'>
                <a href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxVtmoYKakDNmF45fRzXQib0%3D' target='_blank' rel='noopener noreferrer'>
                  取り扱い職種の範囲等の明示
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='mt-8 flex justify-end'>
        <div className='sm:w-full sm:px-4 md:w-1/3 md:px-0'>
          <ul className='text-[12px] text-gray-600'>
            <li className='mb-6'>
              <p>IR情報</p>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/20241029ksk.pdf'
                download={"20241029ksk.pdf"}
              >
                合併公告
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/【第1期】カソーク_決算書.pdf'
                download={"【第1期】カソーク_決算書.pdf"}
              >
                第１期&emsp;決算公告
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/20240619bs.pdf'
                download={"20240619bs.pdf"}
              >
                第２期&emsp;決算公告
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/【第1期】カソーク_個別注記表_202303.pdf'
                download={"【第1期】カソーク_個別注記表_202303.pdf"}
              >
                第１期&emsp;個別注記法
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/20240619chuki.pdf'
                download={"20240619chuki.pdf"}
              >
                第２期&emsp;個別注記法
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/20240620cr.pdf'
                download={"20240620cr.pdf"}
              >
                資本金及び準備金の額の減少公告
              </a>
              <p className='mt-4'>
                2023年6月1日をもって、カソーク株式会社との吸収合併により株式会社ココドルは消滅しております。株式会社ココドルの決算公告については、下記のPDFをご確認ください。
              </p>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/【第6期】ココドル_決算書.pdf'
                download={"【第6期】ココドル_決算書.pdf"}
              >
                第６期&emsp;株式会社ココドル&emsp;決算公告
              </a>
            </li>
            <li className='mb-6'>
              <a
                className='border-b border-gray-500'
                href='/pdf/【第6期】ココドル_個別注記表_202303.pdf'
                download={"【第6期】ココドル_個別注記表_202303.pdf"}
              >
                第６期&emsp;株式会社ココドル&emsp;個別注記表
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className='mt-4A flex w-full  justify-end pb-4 text-[10px] italic sm:pr-8 md:pr-4'>
        <p>© kasooku inc. All right reserved.</p>
      </div>
    </div>
  );
}

export default FooterPage;
