import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import EditButtonGroup from "../parts/EditButtonGroup";
import RequiredBadge from "../parts/RequiredBadge";
import SelectBox from "../parts/SelectBox";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { apiPost } from "@/utils/api";

interface LastEducation {
  school_type: string;
  school_id: number | undefined;
  school_name: string;
  department: string | undefined;
  graduated_year: string | undefined;
}

interface School {
  id: number;
  name: string;
}

interface Props {
  profileSubmit?: () => void;
  delete?: () => void;
  cancel?: () => void;
  isProfile: boolean;
}

const schools: School[] = [
  { id: 1, name: "高校卒" },
  { id: 2, name: "専門卒" },
  { id: 3, name: "短大卒" },
  { id: 4, name: "高専卒" },
  { id: 5, name: "大学卒" },
  { id: 6, name: "大学修卒（修士）" },
  { id: 7, name: "大学修卒（MBA）" },
  { id: 8, name: "大学修卒（博士）" },
];

const schema = yup.object({
  school_type: yup.string().required("最終学歴は必須入力項目です"),
  school_name: yup.string().required("学校名は必須入力項目です").max(255, "学校名は255文字以内にしてください。"),
  school_id: yup.number(),
  department: yup.string().max(255, "学部・学科は255文字以内にしてください。"),
  graduated_year: yup
    .string()
    .matches(/^\d{4}$/, { message: "正しい形式で入力してください (例: 2023)", excludeEmptyString: true }),
});

export const EditLastEducation = (props: Props) => {
  useControlBlowserBack();
  const { profileSubmit, cancel, delete: deleteFunc, isProfile } = props;

  const navigate = useNavigate();
  const { error } = useToast();
  const [user] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<LastEducation>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const lastEducationValue = schools.find((v) => v.id === Number(watch().school_type))?.name;

  const onSubmit: SubmitHandler<LastEducation> = async (params) => {
    const updateParams = !isProfile
      ? {
          ...params,
          school_id: "",
          register_step: 5,
        }
      : { ...params, id: user.user_schools.id, register_step: 5 };
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiPost("/api/user/school", updateParams);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && !isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/current-company" }));
      navigate("/register/current-company");
      return;
    }
    if (res.code === 200 && profileSubmit) {
      profileSubmit();
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  useEffect(() => {
    if (isProfile) {
      const firstSchool = user.user_schools;
      setValue("department", firstSchool.department || "");
      setValue("graduated_year", firstSchool.graduated_year?.toString() || "");
      setValue("school_id", firstSchool.school_id || 0);
      setValue("school_name", firstSchool.school_name || "");
      setValue("school_type", firstSchool.school_type?.toString() || "");
    }
  }, [isProfile, user, setValue]);

  return (
    <div
      className={` ${
        isProfile ? "rounded-[6px] bg-blue-50 p-[24px]" : "absolute w-[336px] sm:top-[110px] md:top-[90px]"
      }`}
    >
      <Loading />
      <div className='flex-col'>
        <div className='mb-[10px] flex flex-col gap-1'>
          <div className='flex items-center'>
            <label
              htmlFor='birthday'
              className={`text-[13px] font-bold leading-5 ${isProfile ? "text-black-600" : ""}`}
            >
              最終学歴
            </label>
            <span className='ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </div>
          <SelectBox
            data={lastEducationValue ?? ""}
            options={schools}
            errors={errors.school_type}
            select={(value: string | number) => {
              setValue("school_type", value.toString());
            }}
          />
        </div>
        <div className='mb-[10px] flex flex-col gap-1'>
          <div className='flex items-center'>
            <label
              htmlFor='schoolName'
              className={`text-[13px] font-bold leading-5 ${isProfile ? "text-black-600" : ""}`}
            >
              学校名
            </label>
            <span className='ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
              必須
            </span>
          </div>
          <input type='text' className='h-10 rounded-md border border-gray-200 p-3' {...register("school_name")} />
          <span className='p-1 text-xs text-red-500'>{errors?.school_name?.message}</span>
        </div>
        <div className='mb-[10px] flex flex-col gap-1'>
          <div className='flex items-center'>
            <label
              htmlFor='department'
              className={`text-[13px] font-bold leading-5 ${isProfile ? "text-black-600" : ""}`}
            >
              学部・学科
            </label>
          </div>
          <input type='text' className='h-10 rounded-md border border-gray-200 p-3' {...register("department")} />
          <span className='p-1 text-xs text-red-500'>{errors?.department?.message}</span>
        </div>
        <div className='mb-[10px] flex flex-col gap-1'>
          <div className='flex items-center'>
            <label
              htmlFor='graduateYear'
              className={`text-[13px] font-bold leading-5 ${isProfile ? "text-black-600" : ""}`}
            >
              卒業年度
            </label>
          </div>
          <div className='flex items-center gap-2'>
            <input
              type='text'
              placeholder='2023'
              className='h-10 w-[83px] rounded-md border border-gray-200 p-3'
              {...register("graduated_year")}
            />
            年度
          </div>
          <span className='p-1 text-xs text-red-500'>{errors?.graduated_year?.message}</span>
        </div>
        {isProfile ? (
          <div className='flex sm:justify-center md:flex-row md:items-center md:justify-end'>
            <EditButtonGroup submit={handleSubmit(onSubmit)} cancel={cancel} />
          </div>
        ) : (
          <button
            type='submit'
            className='my-1 w-full rounded-full bg-blue-700 py-3 text-center text-white hover:bg-blue-500 focus:outline-none'
            onClick={handleSubmit(onSubmit)}
          >
            次へ
          </button>
        )}
      </div>
    </div>
  );
};

export default EditLastEducation;
