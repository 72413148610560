import { useState } from "react";

import { useRecoilState } from "recoil";

import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditDesireIndustry from "@/components/layouts/profile/EditDesireIndustry";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import { userSelector } from "@/stores/selectors/userSelector";

function DesireIndustryPage() {
  useControlBlowserBack();
  const [isOpenIndustry, setIsOpenIndustry] = useState(true);
  const [user] = useRecoilState(userSelector);
  const [industryId, setIndustryId] = useState<number[]>(
    user.user_desired_industries.map((item) => item.industry_id) as unknown as number[],
  );

  return (
    <div className='flex min-h-screen flex-col bg-blue-100'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div
          className={`text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] ${
            isOpenIndustry ? "sm:h-[630px] md:h-[700px]" : "h-[400px]"
          } sm:w-[375px] md:top-[111px] md:w-[760px]`}
        >
          <ProgressBar progress={17} />
          <EditDesireIndustry
            isProfile={false}
            setIsOpenIndustry={setIsOpenIndustry}
            setIndustryId={setIndustryId}
            industryId={industryId}
          />
          <div className='absolute text-[14px] sm:top-[110px] sm:w-4/5 md:top-[90px] md:w-[600px]'>
            <div>
              <label htmlFor='DesireIndustry' className='font-bold'>
                希望業種を選択する
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesireIndustryPage;
