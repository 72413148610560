import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { User } from "@/types/user";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const userState = atom<User>({
  key: "user",
  default: {
    id: null,
    last_displayed_at: null,
    last_name: "",
    first_name: "",
    last_name_kana: "",
    first_name_kana: "",
    birthday: "",
    gender: null,
    email: "",
    phone_number: "",
    postal_code: "",
    address: "",
    annual_income: null,
    current_industry: null,
    current_job: null,
    current_company: "",
    register_step: null,
    user_schools: {
      id: null,
      school_type: null,
      school_id: null,
      school_name: "",
      department: "",
      graduated_year: null,
    },
    user_skills: [],
    job_history: null,
    industry_id: [],
    job_id: [],
    user_careers: [],
    desired_working_hours: null,
    desired_working_time_zone: null,
    desired_unit_price: null,
    user_desired_industries: [],
    user_desired_job_types: [],
    resumes: null,
    willingness_side_job: null,
    willingness_job_change: null,
    sending_agreement: false,
    header_image: "",
  },
  effects_UNSTABLE: [persistAtom],
});
