import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { apiPatch } from "@/utils/api";

interface Email {
  [key: string]: string | number;
}

function EmailPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    email: yup
      .string()
      .required("メールアドレスは必須入力項目です")
      .email("有効なメールアドレスを入力してください")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "有効なメールアドレスを入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Email> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 1;
    const res = await apiPatch<undefined, { email: string[] }>("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/password" }));
      navigate("/register/password");
      return;
    }

    res.code === 422 && res.errors && error(res.errors["email"][0]);
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[312px] sm:w-full md:top-[111px] md:h-[312px] md:w-[760px]'>
          <ProgressBar progress={3} />
          <div className='absolute w-[336px] text-[13px] sm:top-[110px] md:top-[90px]'>
            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='email' className='text-[14px] font-bold'>
                  メールアドレス
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("email")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.email?.message}</span>
              </div>
            </div>

            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailPage;
