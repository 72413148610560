import { useState } from "react";

import { useRecoilState } from "recoil";

import EditDesireIndustry from "@/components/layouts/profile/EditDesireIndustry";
import { userSelector } from "@/stores/selectors/userSelector";

interface Props {
  setIsAddDesireIndustry: (arg0: boolean) => void;
  isAddDesireIndustry: boolean;
}

const CurrentIndustry = (props: Props) => {
  const [user] = useRecoilState(userSelector);
  const [industryId, setIndustryId] = useState<number[]>(
    user.user_desired_industries.map((item) => item.industry_id) as unknown as number[],
  );

  return (
    <div className='text-[13px] sm:w-[full] md:flex md:w-full'>
      <ul>
        <div className='sm:w-full md:w-full'>
          {props.isAddDesireIndustry && (
            <EditDesireIndustry
              isProfile={true}
              setIsAddDesireIndustry={props.setIsAddDesireIndustry}
              setIsOpenIndustry={props.setIsAddDesireIndustry}
              setIndustryId={setIndustryId}
              industryId={industryId}
            />
          )}
          {!props.isAddDesireIndustry && user.user_desired_industries.length === 0 && (
            <li className='pr-4'>{"現在の業種が選択されていません。"}</li>
          )}
          {!props.isAddDesireIndustry && user.user_desired_industries.length > 0 && (
            <div className='pb-3'>
              {user.user_desired_industries.map((item, index) => (
                <p className='pb-1' key={index}>
                  {item.industry_name}
                </p>
              ))}
            </div>
          )}
        </div>
      </ul>
    </div>
  );
};

export default CurrentIndustry;
