import { useState } from "react";

type WithdrawModalProps = {
  setIsOpenWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenQuestionnaireModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const WithdrawModal = (props: WithdrawModalProps) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const handleCheckbox1Change = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckbox2Change = () => {
    setIsChecked2(!isChecked2);
  };

  const handleCheckbox3Change = () => {
    setIsChecked3(!isChecked3);
  };

  const closeModal = () => {
    props.setIsOpenWithdrawModal(false);
  };

  const handleSubmit = async () => {
    props.setIsOpenWithdrawModal(false);
    props.setIsOpenQuestionnaireModal(true);
  };
  return (
    <div>
      <div className='fixed left-0 top-0 z-10 block h-full w-full cursor-pointer bg-modal-overlay' />
      <div className='fixed left-1/2 z-10 h-fit -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white p-6 sm:top-1/2 sm:w-[95%] sm:px-4 md:top-1/2 md:w-[480px] md:px-8'>
        <div className='w-full'>
          <div className='text-center'>
            <p className='mb-4 text-[14px]'>以下をご確認ください。</p>
          </div>
          <div className='my-2 flex text-red-400'>
            <input type='checkbox' className='mr-2' checked={isChecked1} onChange={handleCheckbox1Change}></input>
            <p className='text-[12px]'>退会すると、今までのデータは全て削除され、復元ができません。</p>
          </div>
          <div className='my-2 flex text-red-400'>
            <input type='checkbox' className='mr-2' checked={isChecked2} onChange={handleCheckbox2Change}></input>
            <p className='text-[12px]'>退会後はお問合せが出来なくなりますので、ご注意ください。</p>
          </div>
          <div className='my-2 flex text-red-400'>
            <input type='checkbox' className='mr-2' checked={isChecked3} onChange={handleCheckbox3Change}></input>
            <p className='text-[12px]'>
              退会すると、kasookuサービスの利用ができなくなり、ご利用には再度登録が必要となります。
            </p>
          </div>
        </div>
        <div className='my-2 w-full text-center'>
          <p className='text-[16px] font-bold'>本当に退会しますか？</p>
        </div>
        <div className='flex gap-[16px] text-[13px]'>
          <button
            type='reset'
            className='my-1 ml-8 h-[40px] w-1/2 rounded-[4px] bg-blue-200 py-3 text-center font-bold leading-5 text-blue-700 hover:bg-blue-100 hover:text-blue-500'
            onClick={closeModal}
          >
            やっぱりやめる
          </button>
          <button
            type='button'
            disabled={!isChecked1 || !isChecked2 || !isChecked3}
            className={`my-1 mr-8 h-[40px] w-1/2 rounded-[4px] py-2 text-center font-bold leading-5 text-white focus:outline-none ${
              !isChecked1 || !isChecked2 || !isChecked3 ? "bg-black-300" : "bg-blue-700 hover:bg-blue-500"
            }`}
            onClick={handleSubmit}
          >
            退会手続きに進む
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
