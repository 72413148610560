import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { apiPost } from "@/utils/api";

interface Email {
  [key: string]: string;
}

function PasswordResetPage(props: { handleValueChange: (arg0: boolean) => void }) {
  useControlBlowserBack();

  const { error } = useToast();

  const schema = yup.object({
    email: yup.string().required("メールアドレスは必須入力項目です").email("有効なメールアドレスを入力してください。"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Email> = async (params) => {
    const res = await apiPost("/api/password/request", params);
    if (res.code === 200) {
      props.handleValueChange(true);
    } else {
      error("パスワードの再発行に失敗しました");
    }
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[340px] sm:w-full md:top-[111px] md:h-[340px] md:w-[760px]'>
          <div className='text-center'>
            <h2 className='mb-6 text-[20px] font-bold'>パスワード再発行</h2>
            <p className='text-[14px]'>登録したメールアドレスを入力してください。</p>
          </div>
          <div className='absolute w-[336px] text-[13px] sm:top-[130px] md:top-[130px]'>
            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='email' className='text-[13px] font-bold'>
                  メールアドレス
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("email")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.email?.message}</span>
              </div>
            </div>
            <p className='mb-4 text-[12px] text-gray-500'>半角英数で入力してください。</p>

            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetPage;
