import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import EditButtonGroup from "../parts/EditButtonGroup";
import SelectButton from "../parts/SelectButton";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { apiPatch, apiPost } from "@/utils/api";

interface Props {
  isProfile: boolean;
  cancel?: () => void;
  profileSubmit?: () => void;
}

interface AnnualIncome {
  [key: string]: string | number;
}

export const EditAnnualIncome = (props: Props) => {
  useControlBlowserBack();
  const { isProfile, cancel, profileSubmit } = props;
  const incomeArr = [
    { label: "400万未満", value: 1 },
    { label: "400万〜500万", value: 2 },
    { label: "500万〜600万", value: 3 },
    { label: "600万〜700万", value: 4 },
    { label: "700万〜800万", value: 5 },
    { label: "800万〜900万", value: 6 },
    { label: "900万〜1,000万", value: 7 },
    { label: "1,000万以上", value: 8 },
  ];

  const navigate = useNavigate();
  const { error } = useToast();
  const [user, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    annual_income: yup.number().required("現年収は必須選択項目です"),
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<{ annual_income: number }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      annual_income: user.annual_income ?? 0,
    },
  });
  const data = watch().annual_income;

  const submit = async (params: AnnualIncome) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 8;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200 && !isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/willingness" }));
      navigate("/register/willingness");
      return;
    }
    if (res.code === 200 && profileSubmit) {
      profileSubmit();
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  return (
    <div
      className={` ${
        isProfile
          ? "rounded-[6px] bg-blue-50 p-[24px]"
          : "absolute w-[400px] sm:top-[110px] sm:max-w-[320px] md:top-[90px] md:max-w-[400px]"
      }`}
    >
      <Loading />
      {!isProfile && (
        <label htmlFor='annualIncome' className='text-[14px] font-bold'>
          現年収
        </label>
      )}
      <div className='mt-5 flex flex-col gap-5'>
        <div className={`flex flex-wrap ${!isProfile && "justify-start"} sm:gap-2.5 md:gap-5`}>
          {incomeArr.map((v) => (
            <SelectButton
              key={v.value}
              isSelected={data === v.value}
              select={(value: number) => {
                if (isProfile) {
                  setValue("annual_income", value);
                } else {
                  setValue("annual_income", value);
                  submit({
                    annual_income: value,
                  });
                }
              }}
              value={v.value}
            >
              {v.label}
            </SelectButton>
          ))}
        </div>
      </div>
      {errors && <span className='p-1 text-xs text-red-500'>{errors.annual_income?.message}</span>}
      {isProfile && (
        <div className='flex items-center justify-end'>
          <EditButtonGroup submit={handleSubmit(submit)} cancel={cancel} />
        </div>
      )}
    </div>
  );
};

export default EditAnnualIncome;
