import { Link as Scroll } from "react-scroll";

function SideMenue() {
  return (
    <div className='rounded-lg border  sm:w-[0px] md:w-[180px]'>
      <ul className='text-[14px] text-base text-gray-700 '>
        <li className='mx-[14px] my-[14px]'>
          <Scroll to='change-email' className='cursor-pointer hover:text-blue-500  sm:text-white md:text-gray-600'>
            メールアドレス変更
          </Scroll>
        </li>
        <li className='mx-[14px] my-[14px]'>
          <Scroll to='change-password' className='cursor-pointer hover:text-blue-500  sm:text-white md:text-gray-600'>
            パスワード変更
          </Scroll>
        </li>
        <li className='mx-[14px] my-[14px]'>
          <Scroll to='withdraw' className='cursor-pointer hover:text-blue-500  sm:text-white md:text-gray-600'>
            退会
          </Scroll>
        </li>
      </ul>
    </div>
  );
}

export default SideMenue;
