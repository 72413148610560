import { useState } from "react";

import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditResume from "@/components/layouts/profile/EditResume";

function Resume() {
  const [, setIsAddResume] = useState(false);

  return (
    <div className='bg-blue-100  md:h-screen'>
      <div className='flex flex-col'>
        <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2 pt-16'>
          <div className='text-black absolute flex justify-center rounded bg-white px-6 pt-8 sm:top-[88px] sm:h-[100px] sm:w-full md:top-[111px] md:h-[100px] md:w-[760px]'>
            <ProgressBar progress={14} />
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='mt-[60px] justify-center bg-white sm:w-full md:w-[760px]'>
            <EditResume setIsAddResume={setIsAddResume} bgColor={"bg-white"} isProfile={false}></EditResume>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
