import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import SelectBox from "../parts/SelectBox";

import Badge from "@/components/layouts/parts/Badge";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User, Willingness } from "@/types/user";
import { apiPatch, apiPost } from "@/utils/api";
import { getMe } from "@/utils/me";

interface Option {
  id: number;
  name: string;
}

interface Props {
  isProfile?: boolean;
  cancel?: () => void;
  profileSubmit?: () => void;
}

export const EditWillingness = (props: Props) => {
  useControlBlowserBack();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  const { isProfile } = props;
  const { error, success } = useToast();
  const [conditions, setWillingness] = useState<Willingness>({
    willingness_job_change: user.willingness_job_change,
    willingness_side_job: user.willingness_side_job,
    sending_agreement: user.sending_agreement,
  });

  const careerChangeOptions: Option[] = [
    {
      id: 1,
      name: "2～3ヶ月以内に転職したい",
    },
    {
      id: 5,
      name: "6ヶ月以内に転職したい",
    },
    {
      id: 6,
      name: "1年以内に転職したい",
    },
    {
      id: 3,
      name: "良いところがあれば",
    },
    {
      id: 2,
      name: "退職間近または離職中",
    },
    {
      id: 4,
      name: "転職は考えていない",
    },
  ];

  const sideJobOptions: Option[] = [
    {
      id: 2,
      name: "積極的に探している",
    },
    {
      id: 3,
      name: "複業は考えていない",
    },
    {
      id: 1,
      name: "良いところがあれば",
    },
  ];

  const schema = yup.object({
    willingness_job_change: yup.number().required(),
    willingness_side_job: yup.number().required(),
    sending_agreement: yup.boolean().required(),
  });

  const {
    setValue,
    formState: { errors },
    watch,
  } = useForm<{ willingness_job_change: number; willingness_side_job: number; sending_agreement: boolean }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      willingness_job_change: user.willingness_job_change as number,
      willingness_side_job: user.willingness_side_job as number,
      sending_agreement: user.sending_agreement as boolean,
    },
  });

  const willingness_job_change_data =
    careerChangeOptions && careerChangeOptions.find((v) => v.id === Number(watch().willingness_job_change))?.name;
  const willingness_side_job_data =
    sideJobOptions && sideJobOptions.find((v) => v.id === Number(watch().willingness_side_job))?.name;

  const handleSubmit = async () => {
    const params = Object.fromEntries(Object.entries(conditions).filter(([key, value]) => value !== null));
    let url = "willingness-job-change";
    if (conditions.willingness_side_job !== null && conditions.willingness_side_job !== user.willingness_side_job)
      url = "willingness-side-job";
    const res = await apiPost("/api/user/" + url, params);

    if (res.code === 422 && res.errors) return error("登録に失敗しました");

    setUser((prevValue) => ({
      ...prevValue,
      willingness_job_change: params.willingness_job_change,
      willingness_side_job: params.willingness_side_job,
    }));

    if (!props.isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/postal-code" }));
      navigate("/register/postal-code");
      return;
    }

    success("転職・副業意欲を保存しました");
  };

  const handleRegister = async () => {
    try {     
      setKey((prevValue) => ({ ...prevValue, isLoading: true }));
      const res = await apiPatch("/api/register", {
        willingness_job_change: conditions.willingness_job_change,
        willingness_side_job: conditions.willingness_side_job,
        sending_agreement: conditions.sending_agreement,
        register_step: 9,
      });

      if (res.code === 200) {
        getMe().then((user) => {
          user && setUser(user as User);
          setKey((prevValue) => ({ ...prevValue, path: "/register/postal-code", isLoading: false }));
          navigate("/register/postal-code");
        });
        return success("マイページを作成しました");
      }
      setKey((prevValue) => ({ ...prevValue, isLoading: false }));
      res.code !== 200 || (res.errors && error("登録に失敗しました"));
    } catch (error) {
      console.error(error);
      setKey((prevValue) => ({ ...prevValue, isLoading: false }));
    }
  };

  const handleOptionSelect = (optionId: unknown, fieldName: string) => {
    setWillingness({ ...conditions, [fieldName]: optionId });
  };

  const isUpdateProfile = () => {
    // プロフィール: 選択し変更で更新
    return (
      conditions.willingness_job_change !== user.willingness_job_change ||
      conditions.willingness_side_job !== user.willingness_side_job
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isProfile && isUpdateProfile() && handleSubmit();
  }, [conditions]);

  return (
    <div className={`${isProfile ? "form-group w-full rounded-md bg-white p-8 pb-4 pt-0" : "mt-16 w-fit"}`}>
      <div className={`${isProfile && "mr-1"}`}>
        <p className={`text-[14px] sm:mt-4 sm:pt-4 md:mt-0 md:pt-0 ${!isProfile && "font-bold"}`}>転職・複業意向</p>
      </div>
      <div className='md:flex '>
        <div className='my-4 mr-4'>
          <div className='flex'>
            <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>転職意向</p>
            <Badge isDisplay={!user.willingness_job_change && isProfile} />
          </div>
          <SelectBox
            data={willingness_job_change_data ?? "選択してください。"}
            errors={errors.willingness_job_change}
            options={
              careerChangeOptions
                ? careerChangeOptions.map((v) => ({
                  id: v.id,
                  name: v.name,
                }))
                : [
                  {
                    id: 0,
                    name: "",
                  },
                ]
            }
            select={(value: unknown) => {
              setValue("willingness_job_change", value as number);
              handleOptionSelect(value, "willingness_job_change");
            }}
          />
        </div>
        <div className='my-4 mr-4'>
          <div className='flex'>
            <p className='mr-1 py-2 text-[14px] font-bold text-gray-500'>複業意向</p>
            <Badge isDisplay={!user.willingness_side_job && isProfile} />
          </div>
          <SelectBox
            data={willingness_side_job_data ?? "選択してください。"}
            errors={errors.willingness_side_job}
            options={
              sideJobOptions
                ? sideJobOptions.map((v) => ({
                  id: v.id,
                  name: v.name,
                }))
                : [
                  {
                    id: 0,
                    name: "",
                  },
                ]
            }
            select={(value: unknown) => {
              setValue("willingness_side_job", value as number);
              handleOptionSelect(value, "willingness_side_job");
            }}
          />
        </div>
      </div>
      {/* MyPage */}
      {/* {isProfile && <EditSendingAgreement />} */}
      {/* Account Flow */}
      {/* {!isProfile && (
        <div className='justify-center md:w-full pb-4'>
          <span className="text-[14px]">弊社<a className="text-blue-800 hover:underline" href="https://job.kasooku.jp/UN_group" target="_blank" rel="noopener noreferrer">グループ会社</a>でも有力・有望な成長企業の転職求人のご紹介が可能です。</span>
          <div className='flex items-center mt-2'>
            <input
              type='checkbox'
              id="check"
              className='mr-2 mt-2 h-5 w-5 rounded-md border border-gray-200 md:mt-0'
              onChange={() => setWillingness({ ...conditions, sending_agreement: !conditions.sending_agreement })}
            />
            <label htmlFor='check' className='text-[14px] md:mt-[1px]'>弊社グループ会社へ登録情報を共有して求人情報を受け取る</label>
          </div>
        </div>
      )} */}
      {/* submit */}
      {!isProfile && (
        <div className='justify-center md:flex md:w-full'>
          <div className='w-full'>
            {conditions.willingness_job_change && conditions.willingness_side_job ? (
              <button
                type='submit'
                className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                onClick={handleRegister}
              >
                次へ
              </button>
            ) : (
              <button
                disabled
                type='submit'
                className='w-full rounded-full bg-gray-300 py-3 text-center text-[13px] font-bold text-white sm:my-2 md:m-2'
              >
                次へ
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditWillingness;
