import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

function Career() {
  const [user] = useRecoilState(userSelector);

  return (
    <div>
      {user.user_careers.length === 0 ? (
        <div className='text-[13px]'>職歴が入力されていません。</div>
      ) : (
        <div className='text-[13px]'>
          {user.user_careers.map((item, index) => (
            <div key={index} className='mb-6'>
              <div className='flex text-gray-700'>
                <p className='pr-4 text-[16px] font-bold'>{item.company_name}</p>
                <p className='pr-4 text-[16px]'>
                  {item.start_at as string} - {item.is_employed ? "現在" : (item.end_at as string)}
                </p>
              </div>
              <p className='mt-1 bg-white text-[13px] text-gray-500'>{item.department}</p>
              <p className='mt-1 whitespace-normal break-words bg-white text-[14px]'>{item.content}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Career;
