import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

interface TabProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, active, onClick }) => {
  const activeClasses = active ? "text-blue-700" : "text-gray-500";

  return (
    <div className='inline-block'>
      <button className={`px-4 py-2 font-bold sm:text-sm md:text-lg ${activeClasses}`} onClick={onClick}>
        {label}
      </button>
      {active && <div className='mt-2 h-1 bg-blue-700'></div>}
    </div>
  );
};
const TabContainer = (props: { setActiveTab: React.Dispatch<React.SetStateAction<string>> }) => {
  const [activeTab, setActiveTab] = useState<string>("profile");
  const [key, setKey] = useRecoilState(locationState);

  const handleTabClick = (tab: string) => {
    setKey((prevValue) => ({ ...prevValue, component: tab }));
    setActiveTab(tab);
    props.setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(key.component);
  }, []);

  return (
    <div className='flex justify-start sm:mx-4 md:mx-0'>
      <Tab label='プロフィール' active={activeTab === "profile"} onClick={() => handleTabClick("profile")} />
      <Tab label='求人一覧' active={activeTab === "list"} onClick={() => handleTabClick("list")} />
    </div>
  );
};

export default TabContainer;
