import { selector } from "recoil";

import { userState } from "@/stores/atoms/userAtom";
import { User } from "@/types/user";

export const userSelector = selector<User>({
  key: "userSelector",
  get: ({ get }) => {
    const user: User = get(userState);

    return user;
  },
  set: ({ set }, newValue) => {
    set(userState, newValue);
  },
});
