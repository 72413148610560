import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { apiPatch } from "@/utils/api";

interface Gender {
  [key: string]: string | number;
}

function GenderPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    gender: yup.number().required("性別は必須選択項目です"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ gender: number }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const select: SubmitHandler<Gender> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 3;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/birthday" }));
      navigate("/register/birthday");
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };
  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[419px] sm:w-[375px] md:top-[111px] md:h-[400px] md:w-[760px]'>
          <ProgressBar progress={5} />
          <div className='absolute w-[336px] text-[13px] sm:top-[110px] md:top-[90px]'>
            <div className='flex-col gap-5'>
              <div>
                <button
                  className='mx-auto my-4 flex h-12 w-56 justify-center rounded border border-blue-800 p-3.5 text-center text-sm font-bold text-blue-800 focus:bg-blue-800 focus:text-white'
                  onClick={() => {
                    setValue("gender", 1);
                    select({ gender: 1 });
                  }}
                >
                  男性
                </button>
              </div>
              <div>
                <button
                  className='mx-auto my-4 flex h-12 w-56 justify-center rounded border border-blue-800 p-3.5 text-center text-sm font-bold text-blue-800 focus:bg-blue-800 focus:text-white'
                  onClick={() => {
                    setValue("gender", 2);
                    select({ gender: 2 });
                  }}
                >
                  女性
                </button>
              </div>
              <div>
                <button
                  className='mx-auto my-4 flex h-12 w-56 justify-center rounded border border-blue-800 p-3.5 text-center text-sm font-bold text-blue-800 focus:bg-blue-800 focus:text-white'
                  onClick={() => {
                    setValue("gender", 3);
                    select({ gender: 3 });
                  }}
                >
                  その他
                </button>
              </div>
            </div>
            <span className='p-1 text-xs text-red-500'>{errors.gender?.message}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenderPage;
