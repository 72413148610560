import { selector } from "recoil";

import { CareerState } from "@/stores/atoms/CareerAtom";

interface Career {
  formInd: number;
}

export const CareerSelector = selector<Career>({
  key: "CareerSelector",
  get: ({ get }) => {
    const formInd: Career = get(CareerState);

    return formInd;
  },
  set: ({ set }, newValue) => {
    set(CareerState, newValue);
  },
});
