type Props = {
  isDisplay?: boolean;
};

export const Badge = ({ isDisplay }: Props) => {
  if (!isDisplay) return <></>;
  return (
    <div className='flex items-center'>
      <span className='rounded-full bg-red-100 px-3 py-0.5 text-xs font-semibold text-red-500'>未設定</span>
    </div>
  );
};

export default Badge;
