import { useRecoilState } from "recoil";

import { masterSelector } from "@/stores/selectors/masterSelector";
import { userSelector } from "@/stores/selectors/userSelector";

function ExperiencedJob() {
  const [user] = useRecoilState(userSelector);
  const [master] = useRecoilState(masterSelector);

  return (
    <div className='text-[13px] sm:w-[full] md:flex md:w-[780px]'>
      <ul>
        <>
          {!user.current_job ? (
            <li className='pr-4'>{"現在の職種が選択されていません。"}</li>
          ) : (
            master.jobs.find((v) => v.id == user.current_job)?.name
          )}
        </>
      </ul>
    </div>
  );
}

export default ExperiencedJob;
