import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { apiPatch } from "@/utils/api";

interface Password {
  [key: string]: string | number;
}

function PasswordPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    password: yup
      .string()
      .required("パスワードは必須入力項目です")
      .min(8, "パスワードは8文字以上にしてください")
      .max(50, "パスワードは50文字以内にしてください")
      .matches(/(?=.*[a-zA-Z])/, "半角英字が1文字以上含まれている必要があります")
      .matches(/(?=.*\d)/, "半角数字が1文字以上含まれている必要があります")
      .matches(
        /^[a-zA-Z0-9!?_+*‘"`#$%&\-^\\@;:,./=~|[\](){}<>“‘]+$/,
        "記号は（!“#$%&‘()‘-=^~|@`[{;+:*]},<.>/?_）が使用できます",
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<Password> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 2;
    const res = await apiPatch("/api/user/password", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/gender" }));
      navigate("/register/gender");
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[360px] sm:w-full md:top-[111px] md:h-[340px] md:w-[760px]'>
          <ProgressBar progress={4} />
          <div className='absolute w-[336px] text-[13px] sm:top-[110px] md:top-[90px]'>
            <div className='form-group'>
              <div className='mr-1'>
                <label htmlFor='password' className='text-[14px] font-bold'>
                  パスワード
                </label>
                <input
                  type={isRevealPassword ? "text" : "password"}
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("password")}
                />
                <span onClick={togglePassword} role='presentation' className='absolute right-[10px] top-[38px]'>
                  {isRevealPassword ? <img src='/images/eye.svg'></img> : <img src='/images/eye_slash.svg'></img>}
                </span>
                <span className='relative p-1 pb-4 text-xs text-red-500'>{errors.password?.message}</span>
              </div>
            </div>
            <p className='mb-4 text-[12px] text-gray-500'>半角英数字記号を8文字以上50字以内</p>

            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordPage;
