export const PathMap = new Map([
  [0, ""],
  [1, "email"],
  [2, "password"],
  [3, "gender"],
  [4, "birthday"],
  [5, "last-education"],
  [6, "current-company"],
  [7, "current-job"],
  [8, "annual-income"],
  [9, "willingness"],
]);

export const RequiredRegisterStep = 9;
export const RequiredProgressStep = 11;
export const OptionalProgressStep = 7;
