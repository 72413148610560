import { useNavigate } from "react-router-dom";

function NoticeReminder() {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate("/login");
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[180px] sm:w-full md:top-[111px] md:h-[180px] md:w-[760px]'>
          <p className='text-[12px] text-gray-500'>登録したメールアドレスへ、パスワード再発行通知を送信しました。</p>
          <div className='absolute w-[336px] text-[13px] sm:top-[100px] md:top-[80px]'>
            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={onSubmit}
            >
              TOPへ戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeReminder;
