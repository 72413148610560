import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import useToast from "@/hooks/useToast";
import { apiPost } from "@/utils/api";

interface Password {
  [key: string]: string;
}

function ResetPasswordPage() {
  const navigate = useNavigate();
  const { error } = useToast();
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token") as string;
  const id = params.get("id") as string;
  const schema = yup.object({
    password: yup
      .string()
      .required("パスワードは必須入力項目です")
      .matches(/^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,}$/, "有効なパスワードを入力してください。"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<Password> = async (params) => {
    params.id = id;
    params.token = token;
    params.password_confirmation = params.password;
    await apiPost("/api/password/reset", params).then((res) => {
      res.code === 200 && navigate("/login");
      res.errors && error("パスワードの変更に失敗しました");
    });
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[300px] sm:w-full md:top-[111px] md:h-[300px] md:w-[760px]'>
          <div className='text-center'>
            <p className='text-[14px]'>新しく設定するパスワードを入力してください。</p>
          </div>
          <div className='absolute w-[336px] text-[13px] sm:top-[90px] md:top-[80px]'>
            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='password' className='text-[13px] font-bold'>
                  パスワード
                </label>
                <input
                  type={isRevealPassword ? "text" : "password"}
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("password")}
                />
                <span onClick={togglePassword} role='presentation' className='absolute right-[10px] top-[32px]'>
                  {isRevealPassword ? <img src='/images/eye.svg'></img> : <img src='/images/eye_slash.svg'></img>}
                </span>
                <span className='absolute p-1 text-xs text-red-500'>{errors.password?.message}</span>
              </div>
            </div>
            <p className='mb-4 text-[12px] text-gray-500'>半角英数字を含む8文字以上</p>

            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              送信する
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
