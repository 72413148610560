import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { apiPatch } from "@/utils/api";

interface Password {
  [key: string]: string;
}

const ChangePassword = () => {
  const isMobile = useMediaQuery();
  const { success, error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [isRevealCurrentPassword, setIsRevealCurrentPassword] = useState(false);
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);

  const schema = yup.object({
    current_password: yup
      .string()
      .required("パスワードは必須入力項目です")
      .min(8, "パスワードは8文字以上にしてください")
      .max(50, "パスワードは50文字以内にしてください")
      .matches(/(?=.*[a-zA-Z])/, "半角英字が1文字以上含まれている必要があります")
      .matches(/(?=.*\d)/, "半角数字が1文字以上含まれている必要があります")
      .matches(
        /^[a-zA-Z0-9!?_+*‘"`#$%&\-^\\@;:,./=~|[\](){}<>“‘]+$/,
        "記号は（!“#$%&‘()‘-=^~|@`[{;+:*]},<.>/?_）が使用できます",
      ),
    new_password: yup
      .string()
      .required("パスワードは必須入力項目です")
      .min(8, "パスワードは8文字以上にしてください")
      .max(50, "パスワードは50文字以内にしてください")
      .matches(/(?=.*[a-zA-Z])/, "半角英字が1文字以上含まれている必要があります")
      .matches(/(?=.*\d)/, "半角数字が1文字以上含まれている必要があります")
      .matches(
        /^[a-zA-Z0-9!?_+*‘"`#$%&\-^\\@;:,./=~|[\](){}<>“‘]+$/,
        "記号は（!“#$%&‘()‘-=^~|@`[{;+:*]},<.>/?_）が使用できます",
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ current_password: string; new_password: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const toggleCurrentPassword = () => {
    setIsRevealCurrentPassword((prevState) => !prevState);
  };

  const togglePassword2 = () => {
    setIsRevealNewPassword((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<Password> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiPatch("/api/user/password/change", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
    if (res.code === 200) {
      setValue("current_password", "");
      setValue("new_password", "");
      success("パスワードの変更が完了しました");
    }
    res.code === 422 && res.errors && error("現在のパスワードが一致しません");
  };

  return (
    <div className='pt-2'>
      <div>
        <p className='mb-4 mt-2 text-[14px] text-gray-400'>
          現在のパスワードと新しく設定したいパスワードを入力してください。
        </p>
        <div className='form-group mb-6'>
          <div>
            <div className='mb-2 mr-1 sm:w-full md:w-1/2'>
              <label htmlFor='current_password' className='text-[13px] font-bold'>
                現在のパスワード
              </label>
              <div className='relative flex'>
                <input
                  autoComplete='new-password'
                  type={isRevealCurrentPassword ? "text" : "password"}
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("current_password")}
                />
                <span
                  onClick={toggleCurrentPassword}
                  role='presentation'
                  className='absolute right-4 top-3 cursor-pointer'
                >
                  {isRevealCurrentPassword ? (
                    <img src='/images/eye.svg'></img>
                  ) : (
                    <img src='/images/eye_slash.svg'></img>
                  )}
                </span>
              </div>
              <span className='absolute py-1 text-xs text-red-500'>{errors.current_password?.message}</span>
            </div>
            <p className='my-6 text-[12px] text-gray-400'>半角英数で入力してください</p>
          </div>

          <div>
            <div className='mb-2 mr-1 sm:w-full md:w-1/2'>
              <label htmlFor='new_password' className='text-[13px] font-bold'>
                新しいパスワード
              </label>
              <div className='relative flex'>
                <input
                  autoComplete='new-password'
                  type={isRevealNewPassword ? "text" : "password"}
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("new_password")}
                />
                <span onClick={togglePassword2} role='presentation' className='absolute right-4 top-3 cursor-pointer'>
                  {isRevealNewPassword ? <img src='/images/eye.svg'></img> : <img src='/images/eye_slash.svg'></img>}
                </span>
              </div>
              <span className='absolute py-1 text-xs text-red-500'>{errors.new_password?.message}</span>
            </div>
            <p className='my-6 text-[12px] text-gray-400'>半角英数で入力してください</p>
          </div>
        </div>
      </div>
      <div className={`w-full ${isMobile && "text-right"}`}>
        <button
          onClick={handleSubmit(onSubmit)}
          className='h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
        >
          変更する
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
