import { industryMainCategory } from "@/types/industry";

export const formatIndustries = (industries?: industryMainCategory[]) => {
  return industries
    ?.map((v) =>
      v.industry_sub_categories
        .map((l) =>
          l.industries.map((x) => ({
            id: x.industry_id,
            name: x.industry_name,
            mainCategoryName: v.industry_category_name,
            mainCategoryId: v.industry_category_id,
            subCategoryName: l.industry_sub_category_name,
            subCategoryId: l.industry_sub_category_id,
          })),
        )
        .flat(),
    )
    .flat();
};
