import { ReactNode, useState } from "react";

import { FieldError, FieldErrors } from "react-hook-form";

import ArrowDropDown from "../common/ArrowDropDown";

interface Props {
  select: (value: string | number) => void;
  data: string;
  errors?: FieldError;
  options: { id: string | number; name: string }[];
}

export const SelectBox = (props: Props) => {
  const { select, data, errors, options } = props;
  const [isOpen, setOpen] = useState(false);
  return (
    <div className='relative'>
      <div className='flex flex-col'>
        <button
          className='relative h-10 w-fit min-w-[250px] cursor-pointer appearance-none rounded-md border border-gray-200 bg-white px-3'
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <div className='flex items-center justify-between'>
            <span className={`${data ? "black text-[13px]" : "text-gray-500"}`}>{data ? data : "選択する"}</span>
            <ArrowDropDown />
          </div>
        </button>
        <span className='p-1 text-xs text-red-500'>{errors?.message}</span>
      </div>

      {isOpen && (
        <div className='bg-black absolute top-[44px] z-10 w-44 divide-y divide-gray-100 rounded-lg bg-white text-[13px] shadow'>
          <ul className='text-black h-fit cursor-pointer py-2' aria-labelledby='dropdownHoverButton'>
            {options.map((v) => (
              <li key={v.id}>
                <div
                  className='block px-4 py-2 hover:bg-blue-100'
                  onClick={() => {
                    setOpen(!isOpen);
                    select(v.id);
                  }}
                >
                  {v.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectBox;
