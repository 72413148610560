import { useState } from "react";

import NoticeReminder from "@/components/layouts/login/NoticeReminder";
import ReminderPassword from "@/components/layouts/login/ReminderPassword";

function ReminderPasswordPage() {
  const [isDisplayNoticePage, setIsDisplayNoticePage] = useState(false);

  const handleValueChange = (newValue: boolean) => {
    setIsDisplayNoticePage(newValue);
  };

  return (
    <div className='font-sans'>
      {!isDisplayNoticePage ? <ReminderPassword handleValueChange={handleValueChange} /> : <NoticeReminder />}
    </div>
  );
}

export default ReminderPasswordPage;
