function LpSideJob() {
  return (
    <iframe
      className='h-screen w-screen'
      src='/lp/kasooku-lp-sidejob/index.html'
      title='副業・転職マッチングサービス kasooku（カソーク'
    ></iframe>
  );
}

export default LpSideJob;
