import { ReactNode } from "react";

interface Props {
  select: (value: number) => void;
  value: number;
  isSelected?: boolean;
  children: ReactNode;
}

export const SelectButton = (props: Props) => {
  const { select, isSelected, children, value } = props;
  return (
    <button
      className={`${
        isSelected ? "bg-blue-800 text-white" : "bg-white text-blue-800"
      } flex h-10 justify-center rounded border border-blue-800   pb-2.5 pt-2.5 text-center text-sm font-bold sm:w-[145px] md:w-[120px] `}
      onClick={() => select(value)}
    >
      {children}
    </button>
  );
};

export default SelectButton;
