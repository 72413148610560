function Lp02() {
  return (
    <iframe
      className='h-screen w-screen'
      src='/lp/lp02/index.html'
      title='[02]副業・転職マッチングサービス kasooku（カソーク'
    ></iframe>
  );
}

export default Lp02;
