import { useState } from "react";

import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditConditions from "@/components/layouts/profile/EditConditions";

function ConditionsPage() {
  const [, setIsAddConditions] = useState(false);

  return (
    <div className='flex min-h-screen flex-col bg-blue-100'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[550px] sm:w-[375px] md:top-[111px] md:h-[550px] md:w-[760px]'>
          <ProgressBar progress={16} />
          <div className='w-full sm:mt-12 md:mt-6 md:px-14'>
            <EditConditions
              setIsAddConditions={setIsAddConditions}
              bgColor={"bg-white"}
              isProfile={false}
              isPopUp={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConditionsPage;
