import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useRecoilState } from "recoil";

import useMediaQuery from "@/hooks/useMediaQuery";
import useScroll from "@/hooks/useScroll";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { ApiResponse, apiGet, apiPost } from "@/utils/api";

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useRecoilState(userSelector);
  const [key, setKey] = useRecoilState(locationState);
  const [isLogin, setIsLogin] = useState(false);
  const scroll = useScroll();
  const isSm = useMediaQuery();
  const [isOpenMenue, setIsOpenMenue] = useState(false);
  const [isOpenLoginMenue, setIsOpenLoginMenue] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState<null | boolean>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const colorStyle = {
    background: `linear-gradient(to right, #2c3c6c, #405494)`,
  };

  const registerPaths = ["/reminder/password", "/reset/password", "/register", "/login"];
  const isRegisterPage = registerPaths.some((path) => {
    if (path === "/register") return location.pathname.startsWith(path);
    else return location.pathname === path;
  });
  const isTopPath = location.pathname === "/";
  const nonLoginPaths = [
    "/reminder/password",
    "/reset/password",
    "/register/legal",
    "/register",
    "/register/sms",
    "/",
    "/jobs",
    "/company",
    "/privacy",
    "/term",
    "/tokutei",
    "/toriatsukai",
    "/nda",
    "/nda/agree",
    "/nda/complete",
    "/service",
    "/tl/01",
    "/tl/02",
    "/tl/03",
    "/tl/04",
  ];
  const isNonLoginPage = nonLoginPaths.some((path) => {
    if (path.endsWith("/")) return location.pathname === path;
    else if (path === "/jobs") return location.pathname.startsWith(path);
    else return location.pathname === path;
  });

  const moveLogin = () => {
    navigate("/login");
  };

  const handleLogin = (isRegisterPage: boolean | null = null) => {
    setIsLoginModal(isRegisterPage);
    setIsOpenLoginMenue((prev) => !prev);
  };

  const handleProfile = () => {
    setIsOpenMenue(false);
    setKey((prev) => ({ ...prev, path: "/profile", component: "profile" }));
    navigate("/profile");
  };

  const handleLogout = () => {
    apiPost("/api/logout");
    setIsOpenMenue(false);
    setIsOpenLoginMenue(false);
    setIsLoginModal(null);
    setTimeout(() => {
      setIsLogin(false);
      setUser({
        id: null,
        last_displayed_at: null,
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        birthday: "",
        gender: null,
        email: "",
        phone_number: "",
        postal_code: "",
        address: "",
        annual_income: null,
        current_industry: null,
        current_job: null,
        current_company: "",
        register_step: null,
        user_schools: {
          id: null,
          school_type: null,
          school_id: null,
          school_name: "",
          department: "",
          graduated_year: null,
        },
        user_skills: [],
        job_history: null,
        industry_id: [],
        job_id: [],
        user_careers: [],
        desired_working_hours: null,
        desired_working_time_zone: null,
        desired_unit_price: null,
        user_desired_industries: [],
        user_desired_job_types: [],
        resumes: null,
        willingness_side_job: null,
        willingness_job_change: null,
        sending_agreement: false,
        header_image: "",
      });
      navigate("/");
    }, 200);
  };

  const checkLogin = async () => {
    if (user.id && isReady) return;
    await apiGet<User>("/api/user/me").then((res: ApiResponse<User>) => {
      if ("data" in res && res.success) {
        setIsLogin(true);
        setUser(res.data);
      } else if (res.code !== 200 && !isNonLoginPage) {
        navigate("/login", { state: { prevPath: key.path } });
      }
    });
    setIsReady(true);
  };

  useEffect(() => {
    if (isReady && user.id) {
      setIsReady(true);
      setIsLogin(true);
      setUser(user);
      return;
    }
    if (!isReady) checkLogin();
  }, []);

  useEffect(() => {
    setIsOpenMenue(false);
  }, [scroll]);

  useEffect(() => {
    if (user.id) setKey((prev) => ({ ...prev, isLoading: false }));
  }, [user, setKey]);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes("jobs/")) setKey((prev) => ({ ...prev, component: "detail" }));
    if (pathname.includes("contacts/new")) setKey((prev) => ({ ...prev, component: "footer" }));
    if (window.location.search) localStorage.setItem("query", window.location.search);
  }, []);

  return {
    isTopPath,
    isLogin,
    isRegisterPage,
    isSm,
    key,
    isOpenMenue,
    menuRef,
    user,
    colorStyle,
    isOpenLoginMenue,
    isLoginModal,
    isReady,
    setIsOpenLoginMenue,
    moveLogin,
    setIsLoginModal,
    setIsOpenMenue,
    handleProfile,
    handleLogin,
    handleLogout,
  };
};

export default useAuth;
