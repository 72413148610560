import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { apiPost, apiDelete } from "@/utils/api";

type QuestionnaireModalProps = {
  setIsOpenQuestionnaireModal: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Questionnaire {
  questionnaires?: number[];
  other_text?: string;
  free_text?: string;
}

const WithdrawModal = (props: QuestionnaireModalProps) => {
  const navigate = useNavigate();
  const { success, error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [user, setUser] = useRecoilState(userSelector);
  const [isChecked, setIsChecked] = useState(false);

  const [questionnaireLists, setQuestionnaireLists] = useState([
    {
      id: 1,
      reason: "自分に合った職種の副業案件がない",
      isChecked: false,
    },
    {
      id: 2,
      reason: "興味のある企業の副業案件がない",
      isChecked: false,
    },
    {
      id: 3,
      reason: "希望に合う条件の副業案件がない",
      isChecked: false,
    },
    {
      id: 4,
      reason: "応募しても紹介してもらえない",
      isChecked: false,
    },
    {
      id: 5,
      reason: "転職の求人が少ない",
      isChecked: false,
    },
    {
      id: 6,
      reason: "キャリアサポート担当者に不満がある",
      isChecked: false,
    },
    {
      id: 7,
      reason: "お仕事期間のサポートに不満がある",
      isChecked: false,
    },
    {
      id: 8,
      reason: "案件紹介のメールが多すぎる",
      isChecked: false,
    },
    {
      id: 9,
      reason: "他のサービス・エージェントを使うことにした",
      isChecked: false,
    },
    {
      id: 10,
      reason: "ご都合により副業ができなくなった",
      isChecked: false,
    },
    {
      id: 11,
      reason: "新しいお仕事が見つかった",
      isChecked: false,
    },
    {
      id: 12,
      reason: "その他",
      isChecked: false,
    },
  ]);

  const schema = yup.object({
    other_text: yup.string().max(200, "その他は200文字以内にしてください"),
    free_text: yup.string().max(200, "自由記入欄は200文字以内にしてください"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const otherText = watch("other_text");

  const closeModal = () => {
    props.setIsOpenQuestionnaireModal(false);
  };

  const handleChange = (id: number) => {
    setQuestionnaireLists((prevLists) =>
      prevLists.map((item) => (item.id === id ? { ...item, isChecked: !item.isChecked } : item)),
    );
  };

  const onSubmit: SubmitHandler<Questionnaire> = async (params) => {
    params.questionnaires = questionnaireLists.filter((item) => item.isChecked).map((item) => item.id);
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiDelete("/api/user/" + user.id, params);
    if (res.code !== 200) {
      setKey((prevValue) => ({ ...prevValue, isLoading: false }));
      error(res.message);
      return;
    }
    await apiPost("/api/logout");
    setUser({
      id: null,
      last_displayed_at: null,
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      birthday: "",
      gender: null,
      email: "",
      phone_number: "",
      postal_code: "",
      address: "",
      annual_income: null,
      current_industry: null,
      current_job: null,
      current_company: "",
      register_step: null,
      user_schools: {
        id: null,
        school_type: null,
        school_id: null,
        school_name: "",
        department: "",
        graduated_year: null,
      },
      user_skills: [],
      job_history: null,
      industry_id: [],
      job_id: [],
      user_careers: [],
      desired_working_hours: null,
      desired_working_time_zone: null,
      desired_unit_price: null,
      user_desired_industries: [],
      user_desired_job_types: [],
      willingness_side_job: null,
      willingness_job_change: null,
      sending_agreement: false,
      resumes: null,
      header_image: "",
    });
    setKey((prevValue) => ({ ...prevValue, isLoading: false, path: "/", component: "withdraw" }));
    success("退会処理が完了しました");
    setTimeout(() => {
      navigate("/");
      window.scrollTo(0, 0);
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    setIsChecked(false);
    const hasCheckedItem = questionnaireLists.some((item) => item.isChecked);
    const otherTextCheck = questionnaireLists.find((item) => item.id === 12);
    if (!otherTextCheck?.isChecked) setValue("other_text", "");
    if (!otherTextCheck?.isChecked || (otherTextCheck?.isChecked && otherText !== "")) setIsChecked(hasCheckedItem);
  }, [questionnaireLists, otherText]);
  return (
    <div>
      <div className='fixed left-0 top-0 z-10 block h-full w-full cursor-pointer bg-modal-overlay' />
      <div className='fixed left-1/2 z-10 max-h-[640px] -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white p-6 sm:top-1/2 sm:w-[95%] sm:px-4 md:top-1/2 md:w-[500px] md:px-8'>
        <div className='w-full'>
          <div className='text-center'>
            <p className='mb-4 text-[14px] font-bold'>退会理由</p>
          </div>
          <p className='text-[13px] sm:mb-2 md:mb-4'>退会する理由をお聞かせください。(当てはまる項目をすべて選択)</p>
          {questionnaireLists.map((item, index) => (
            <div key={index}>
              <div className='flex w-full px-8 sm:my-1 md:my-2'>
                <p className='w-4/5 text-[12px]'>{item.reason}</p>
                <span className='flex w-1/5 justify-end'>
                  <input
                    type='checkbox'
                    className='mr-2'
                    checked={item.isChecked}
                    onChange={() => handleChange(item.id)}
                  ></input>
                </span>
              </div>
              {index === 11 && (
                <div>
                  <div className='my-2 flex w-full px-8'>
                    <input
                      type='text'
                      className='w-2/3 rounded-sm border border-gray-500 px-2 text-sm sm:py-0 md:py-1'
                      {...register("other_text")}
                    />
                  </div>
                  <span className='absolute mt-[-6px] px-8 text-xs text-red-500'>{errors.other_text?.message}</span>
                </div>
              )}
            </div>
          ))}
        </div>
        <div>
          <div className='w-full sm:mb-2 sm:mt-4 md:mb-4 md:mt-6'>
            <p className='mb-2 text-[13px]'>その他、ご意見・ご要望などありましたらご記入ください。</p>
            <textarea
              className='w-full overflow-y-hidden rounded-sm border border-gray-500 px-2 py-1 text-sm'
              {...register("free_text")}
            />
            <span className='relative top-[-6px] text-xs text-red-500'>{errors.free_text?.message}</span>
          </div>
        </div>
        <div className='flex gap-[16px] text-[13px]'>
          <button
            type='reset'
            className='my-1 ml-8 h-[40px] w-1/2 rounded-[4px] bg-blue-200 py-3 text-center font-bold leading-5 text-blue-700 hover:bg-blue-100 hover:text-blue-500'
            onClick={closeModal}
          >
            やっぱりやめる
          </button>
          <button
            type='button'
            disabled={!isChecked}
            className={`my-1 mr-8 h-[40px] w-1/2 rounded-[4px] py-2 text-center font-bold leading-5 text-white focus:outline-none ${
              !isChecked ? "bg-black-300" : "bg-blue-700 hover:bg-blue-500"
            }`}
            onClick={handleSubmit(onSubmit)}
          >
            退会する
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
