import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

function CurrentCompany() {
  const [user] = useRecoilState(userSelector);

  return (
    <div>
      {user.current_company === null ? (
        <div className='text-[13px]'>現職企業名が入力されていません。</div>
      ) : (
        <div className='text-[13px]'>{user.current_company}</div>
      )}
    </div>
  );
}

export default CurrentCompany;
