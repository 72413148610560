import { useRecoilState } from "recoil";

import Carousel from "@/components/layouts/top/Carousel";
import useReloadOnResize from "@/hooks/useReloadOnResize";
import { userSelector } from "@/stores/selectors/userSelector";

const UpperContent = () => {
  useReloadOnResize();
  const [user] = useRecoilState(userSelector);

  const colorStyle = {
    background: `linear-gradient(to right, #2c3c6c, #405494)`,
  };

  return (
    <div className='z-1 relative top-[-10px] w-full overflow-x-hidden overflow-y-hidden sm:mt-1 sm:h-[640px] md:top-0 md:mt-0 md:h-[650px]'>
      <div className='absolute z-[-1] w-screen sm:h-[700px] md:h-[650px]'>
        <Carousel />
      </div>
      <div className=' mt-20 text-[#2c3c6c] sm:w-full sm:p-8 sm:py-4 md:ml-20 md:mt-36 md:w-1/2 md:p-16'>
        <div className='font-bold sm:text-[30px] md:text-[50px]'>
          <p className='sm:h-[36px] md:h-[64px]'>成長企業と共に</p>
          <p className='sm:h-[36px] md:h-[64px]'>新たな挑戦を</p>
        </div>
        <div className='text-[13px] font-bold text-gray-700 sm:pt-5 sm:text-[16px] md:pt-6 md:text-[20px]'>
          <p>これまで培ってきた経験を活かして</p>
          <p>成長企業と共に新たな挑戦をしませんか？</p>
        </div>
        {user.id === null && (
          <a
            href={"/register/legal"}
            className='mb-2 rounded-full py-4 text-center font-bold leading-6 text-gray-200 hover:text-gray-500 focus:outline-none sm:mt-5 sm:block sm:flex sm:w-full sm:text-[14px] md:mt-8 md:flex md:w-[360px] md:text-[16px]'
            style={colorStyle}
          >
            <p className='w-1/5'></p>
            <p className='w-4/5'>新規会員登録はこちら</p>
            <p className='w-1/5 pr-2 text-end'>
              <span>●</span>
            </p>
          </a>
        )}
        <div className='sm:text-center md:w-[360px] md:text-center'>
          {user.id === null && (
            <a href='/login' className='text-[12px] font-bold text-gray-700'>
              すでにアカウントをお持ちの方は
              <span className='border-b border-gray-700  hover:text-gray-900'>ログイン</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpperContent;
