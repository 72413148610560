import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditCurrentJob from "@/components/layouts/profile/EditCurrentJob";

interface CurrentJob {
  [key: string]: string;
}

function CurrentJobPage() {
  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[303px] sm:w-[375px] md:top-[111px] md:h-[284px] md:w-[760px]'>
          <ProgressBar progress={9} />
          <EditCurrentJob isProfile={false} />
        </div>
      </div>
    </div>
  );
}

export default CurrentJobPage;
