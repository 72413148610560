import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function CompanyPage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0'>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <p className='text-[12px] font-bold text-gray-500'>
          2023年1月23日制定
          <br /> 2023年4月1日改定
          <br /> 2024年12月1日改定
          <br />
        </p>
        <p className='text-[28px] font-bold'>Kasooku(カソーク) 利用規約</p>
        <p className='mb-8 border-b border-gray-200 pb-8'>
          本利用規約（以下「本規約」といいます。）には、ユナイテッド・リクルートメント株式会社（以下「当社」といいます。）の提供する本サービス（第2条に定義）のご利用にあたり、利用者の皆様に遵守していただかなければならない事項及び当社と利用者の皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
        </p>
        <p className='mb-4 text-[18px] font-bold'>第1条 適用</p>
        1.本規約は、本サービス（第2条に定義）の利用に関する当社と利用者（第2条に定義）との間の権利義務関係を定めることを目的とし、利用者と当社の間の本サービスの利用に関わる一切の関係に適用されます。
        <br />
        2.当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'>第2条 定義</p>
        本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
        <br />
        (1)「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
        <br />
        (2)「当社ウェブサイト」とは、そのドメインが「kasooku.jp」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
        <br />
        (3)「本サービス」とは、当社が提供するKasooku（カソーク）という名称の副業に関するマッチングのためのサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
        <br />
        (4)「外部SNSサービス」とは、Facebook、その他の他の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、利用者の認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開などの機能を持ち、本サービスの実施に利用されるサービスを意味します。
        <br />
        (5)「外部SNS事業者」とは、外部SNSサービスのサービス提供者を意味します。
        <br />
        (6)「外部SNS利用規約」とは、利用者と外部SNS事業者との権利関係を定める規約を意味します。
        <br />
        (7)「利用者」とは、当社との間で第3条の定めに従い本サービスの利用に係る契約を締結した者を意味します。
        <p className='mb-4 mt-8 text-[18px] font-bold'>第3条 登録</p>
        1.本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、利用者としてアカウント登録をすることができるものとします。かかるアカウント登録が完了した場合、本規約の諸規定に従った本サービスの利用にかかる契約（以下「サービス利用契約」といいます。）が利用者と当社の間に成立します。
        <br />
        2.前項の登録は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人（但し、自らの従業員は除く。）による登録は認められません。また、本サービスの利用を希望する者は、登録にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
        <br />
        3.利用者は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'>第4条 本サービスの利用等</p>
        1.利用者は、サービス利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
        <br />
        2.利用者は、自己が本サービス上で登録をしたメールアドレス宛に、当社から求人企業の求人情報に関する通知がなされることにつき予め同意するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第5条 アカウント情報の管理</p>
        1.利用者は、自己の責任において、本サービスにかかるユーザーID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
        <br />
        2.アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は利用者が負うものとし、当社は一切の責任を負いません。
        <br />
        3.利用者は、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        禁止行為
        <br />
        (1)
        営業、宣伝、広告、勧誘、会員の募集など営利を目的とする行為（当社の認めたものを除きます）その他本サービスが本来予定している目的とは異なる目的で本サービスを利用する行為
        <br />
        (2)
        当社、又は他の利用者、求人企業、外部SNS事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
        <br />
        (3) 犯罪行為に関連する行為又は公序良俗に反する行為 <br />
        (4) 猥褻な情報又は青少年に有害な情報を送信する行為 <br />
        (5) 異性交際に関する情報を送信する行為
        <br />
        (6) 法令又は当社若しくは利用者が所属する業界団体の内部規則に違反する行為
        <br /> (7) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為 <br />
        (8) 本サービスに関し利用しうる情報を改ざんする行為
        <br /> (9) 当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
        <br />
        (10)当社による本サービスの運営を妨害するおそれのある行為 <br />
        (11)その他、当社が不適切と判断する行為
        4.当社は、本サービスにおける利用者による情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、利用者に事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第6条 本サービスの停止等</p>
        1.当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        <br />
        (1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
        <br /> (2) コンピューター、通信回線等が事故により停止した場合 <br />
        (3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合 <br />
        (4) 外部SNSサービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合{" "}
        <br />
        (5) その他、当社が停止又は中断を必要と判断した場合
        <br />
        2.当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は利用者に事前に通知するものとします。
        <br /> 3.当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
        <p className='mb-4 mt-8 text-[18px] font-bold'>第7条 設備の負担等</p>
        1.本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、利用者の費用と責任において行うものとします。
        <br />
        2.利用者は自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        <br />
        3.当社は、利用者が送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除に基づき利用者に生じた損害について一切の責任を負いません。
        <br />
        4.利用者は、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を利用者のコンピューター等にインストールする場合には、利用者が保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は利用者に発生したかかる損害について一切責任を負わないものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第8条 権利帰属</p>
        <br />{" "}
        1.当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。利用者は、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
        <br />{" "}
        2.当社ウェブサイト又は本サービスにおいて、利用者が投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、本サービスの提供、当社の新サービスの企画開発、統計資料の作成の目的で無償で利用することができるものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第9条 登録取消等</p>
        1.当社は、利用者が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該利用者について本サービスの利用を一時的に停止し、又は利用者としての登録を取り消すことができます。
        <br /> (1) 本規約のいずれかの条項に違反した場合又は違反するものと当社が合理的に判断した場合 <br />
        (2) 登録情報に虚偽の事実があることが判明した場合又は虚偽の事実であるものと当社が合理的に判断した場合
        <br /> (3)
        当社、他の利用者、求人企業、外部SNS事業者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
        <br /> (4)
        外部SNS利用規約に違反したことその他の理由によって、利用者が外部SNS事業者から、そのサービスの提供や連携を受けられなくなった場合
        <br /> (5) 手段の如何を問わず、本サービスの運営を妨害した場合又は妨害するものと当社が合理的に判断した場合
        <br /> (6)
        支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        <br /> (7)
        自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
        <br /> (8) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合 <br />
        (9) 租税公課の滞納処分を受けた場合
        <br /> (10)死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
        <br /> (11)6ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
        <br /> (12)過去に本サービスの利用の登録を取り消された者であることが判明した場合
        <br />{" "}
        (13)未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ずに登録をしたことが判明した場合
        <br />{" "}
        (14)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
        <br /> (15)その他、当社が利用者としての登録の継続を適当でないと判断した場合
        2.前項各号のいずれかの事由に該当した場合、利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        3.当社及び利用者は、それぞれ30日前までに当社所定の方法で相手方に通知することにより、利用者の登録を取り消すことができます。但し、当社所定の有償サービスを利用する求人企業は、当該有償サービス利用期間中、登録の取消し及び有償サービスの利用停止をすることはできないものとし、利用期間中に中途解約をする場合には、残りの有償サービス利用期間分の利用料金を支払わなければならないものとします。
        4.当社は、本条に基づき当社が行った行為により利用者に生じた損害について一切の責任を負いません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第10条 保証の否認及び免責</p>
        1.本サービスは現状有姿で提供されるものであり、当社は本サービスについて、業務委託先確保の確実性、副業での業務提供先確保の確実性、求人情報の当社ウェブサイトへの掲載の確実性及び継続性、本サービスの特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
        <br />{" "}
        2.利用者が当社から直接又は間接に、求人情報、利用者に関する情報、又は本サービス、当社ウェブサイト、本サービスの他の利用者その他の事項に関する何らかの情報を得た場合であっても、当社は利用者に対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
        <br />
        3.本サービスは、外部SNSサービスと連携することがありますが、かかる連携を保証するものではなく、本サービスにおいて外部SNSサービスと連携できなかった場合でも、当社は一切の責任を負いません。
        <br />{" "}
        4.本サービスが外部SNSサービスと連携している場合において、利用者は外部SNS利用規約を自己の費用と責任で遵守するものとし、利用者と当該外部SNSサービスを運営する外部SNS事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
        <br />
        5.利用者は、本サービスを利用することが、利用者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、利用者による本サービスの利用が、利用者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
        <br />{" "}
        6.本サービス又は当社ウェブサイトに関連して利用者と他の利用者、求人企業、外部SNS事業者その他の第三者との間において生じた取引、連絡、紛争等については、利用者の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
        <br />{" "}
        7.当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、利用者のメッセージ又は情報の削除又は消失､利用者の登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して利用者が被った損害につき、賠償する責任を一切負わないものとします。
        <br />{" "}
        8.当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
        <br />{" "}
        9.当社は、本サービスに関連して利用者が被った損害について、一切賠償の責任を負いません。消費者契約法の適用その他の理由により当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去6ヵ月の期間に利用者から現実に受領した本サービスの利用料金の総額を上限とします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第11条 利用者の賠償等の責任</p>
        1.利用者は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
        <br />{" "}
        2.利用者が、本サービスに関連して他の利用者、求人企業、外部SNS事業者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、利用者の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        <br />{" "}
        3.利用者による本サービスの利用に関連して、当社が、他の利用者、求人企業、外部SNS事業者その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、利用者は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第12条 秘密保持</p>
        1.本規約において「秘密情報」とは、サービス利用契約又は本サービスに関連して、利用者が、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
        <br />{" "}
        2.利用者は、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
        <br />{" "}
        3.第2項の定めに拘わらず、利用者は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
        <br />{" "}
        4.利用者は、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
        <br />{" "}
        5.利用者は、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第13条 個人情報</p>
        本サービスにおける個人情報の取り扱いに関しては、当社が定める「プライバシーポリシー」に基づき取り扱います。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第14条 有効期間</p>
        サービス利用契約は、利用者について第3条に基づく登録が完了した日に効力を生じ、当該利用者の登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と利用者との間で有効に存続するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第15条 本規約等の変更</p>
        1.当社は、本サービスの内容を自由に変更できるものとします。
        <br />{" "}
        2.当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更した場合には、利用者に当該変更内容を通知するものとし、当該変更内容の通知後、利用者が本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、利用者は、本規約の変更に同意したものとみなします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第16条 連絡/通知</p>
        本サービスに関する問い合わせその他利用者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から利用者に対する連絡又は通知は、当社の定める方法で行うものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第17条 本規約の譲渡等</p>
        1.利用者は、当社の書面による事前の承諾なく、サービス利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        <br />{" "}
        2.当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第18条 完全合意</p>
        本規約は、本規約に含まれる事項に関する当社と利用者との完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と利用者との事前の合意、表明及び了解に優先します。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第19条 分離可能性</p>
        本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第20条 存続規定</p>
        第5条（アカウント情報の整理）第2項、第6条（本サービスの停止等）第2項、第7条（設備の負担等）第3項、第8条（権利帰属）、第9条（登録取消等）、第10条（補償の否認及び免責）第2項及び第4項、第11条（利用者の賠償等の責任）から第14条（有効期間）まで、並びに第18条（完全合意）から第22条（狭義解決）までの規定はサービス利用契約の終了後も有効に存続するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第21条 準拠法及び管轄裁判所</p>
        本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 第22条 協議解決</p>
        当社及び利用者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
      </div>
    </div>
  );
}

export default CompanyPage;
