import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const locationState = atom<{ path: string; component: string; tab: string; isLoading: boolean }>({
  key: "location",
  default: {
    path: "",
    component: "profile",
    tab: "side-job",
    isLoading: false,
  },
  effects_UNSTABLE: [persistAtom],
});
