import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";
import { JobDetail } from "@/types/job";

function Top(props: { job: JobDetail }) {
  const [key, setKey] = useRecoilState(locationState);

  useEffect(() => {
    if (props.job.job_type)
      setKey((prevValue) => ({ ...prevValue, tab: props.job.job_type === 1 ? "side-job" : "career-change" }));
  }, [props.job.job_type]);

  if (!props.job) return <></>;

  return (
    <div className={`rounded-md bg-white sm:p-4 md:p-8`}>
      <div>
        {props.job?.header_image ? (
          <img
            src={props.job?.header_image}
            alt=''
            className='w-full rounded-[30px] sm:max-h-[150px] md:max-h-[300px]'
          />
        ) : (
          <img src='/images/ic_no_job.svg' alt='' className='w-full rounded-[30px] sm:max-h-[150px] md:max-h-[300px]' />
        )}
      </div>
      <div
        className={`text-[14px] ${
          key.tab === "side-job" ? "border-blue-700 text-blue-700" : "border-green-600 text-green-600"
        } mt-6 w-fit rounded-sm border px-2 pt-[0.5px] font-bold`}
      >
        {key.tab === "side-job" ? "複業" : "転職"}
      </div>
      {props.job.job_type_name && (
        <div className='mb-4 mt-2 flex'>
          <p className='w-auto rounded-2xl bg-blue-800 px-4 pb-1 pt-2 text-[13px] text-white'>
            {props.job?.job_type_name}
          </p>
        </div>
      )}
      <div className='mb-4 text-[20px] font-bold'>
        <p>{props.job?.title}</p>
      </div>
      <div className='flex text-[16px] text-gray-400'>
        <p>{props.job?.company_name}</p>&nbsp; &nbsp;
        <p>{props.job?.location}</p>
      </div>
      <div className='mt-4 text-[12px] text-gray-400'>
        <p>最終更新&nbsp;{props.job?.updated_at}</p>
      </div>
    </div>
  );
}

export default Top;
