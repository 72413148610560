import { useState, useEffect } from "react";

import { useRecoilState } from "recoil";

import { masterState } from "@/stores/atoms/masterAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { industryMainCategory } from "@/types/industry";
import { ApiResponse, apiGet } from "@/utils/api";
import { formatIndustries } from "@/utils/formatIndustry";

const CurrentIndustry = () => {
  const [user] = useRecoilState(userSelector);
  const [master, setMaster] = useRecoilState(masterState);
  const [currentIndustryName, setCurrentIndustryName] = useState<string>("");

  useEffect(() => {
    if (master.industries && master.industries.length > 0) return;
    const fetchIndustries = async () => {
      try {
        const res: ApiResponse<industryMainCategory[]> = await apiGet<industryMainCategory[]>("/api/industries");
        if (res.success) {
          if ("data" in res) {
            setMaster({ ...master, industries: res.data });
          }
        } else {
          console.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchIndustries();
  }, [user]);

  useEffect(() => {
    if (!master.industries) return;
    const formatArr = formatIndustries(master.industries);

    if (!formatArr) return;
    const currentDisplayName = formatArr.find((v) => v.id === user.current_industry);
    setCurrentIndustryName(
      currentDisplayName
        ? `${currentDisplayName.mainCategoryName} / ${currentDisplayName.subCategoryName} / ${currentDisplayName.name}`
        : "",
    );
  }, [master, user]);
  return (
    <div className='text-[13px] sm:w-[full] md:flex md:w-full'>
      <ul>
        <>
          {!user.current_industry ? (
            <li className='pr-4'>{"現在の業種が選択されていません。"}</li>
          ) : (
            currentIndustryName
          )}
        </>
      </ul>
    </div>
  );
};

export default CurrentIndustry;
