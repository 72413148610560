import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function CompletePage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='w-full overflow-x-hidden bg-blue-50 text-center sm:mt-4 md:mt-0'>
      <div className='rounded-lg bg-white pb-40 pt-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        ありがとうございます。 <br />
        <br />
        改めて、弊社の貴社担当者より ご連絡いたしますので、お待ちいただけますと幸いです。
        <br />
        ※本ページは閉じていただいて結構です。
        <br />
        <br /> 弊社のリニューアルされたHPもぜひご覧ください。
        <div className='mb-4 mt-8 flex justify-center'>
          <img src='/images/logo.svg ' alt='logo'></img>
        </div>
        <a href='https://corp.kasooku.jp/' target='_blank' rel='noopener noreferrer' className='hover:text-gray-500'>
          https://corp.kasooku.jp/
        </a>
      </div>
    </div>
  );
}

export default CompletePage;
